import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { Config } from '../utils/config';

export interface IPrivateRouteProps extends RouteProps {
	isAuth: boolean | undefined; // is authenticate route
}

export const PrivateRoute: React.FC<React.PropsWithChildren<IPrivateRouteProps>> = (props) => {
	if (props.isAuth === true) return <Route {...props} />;
	else if (props.isAuth === false)
		return (
			<Route path={props.path} exact={props.exact}>
				<Redirect to={Config.UI_LOGIN_PAGE} />
			</Route>
		);
	else return null; // neither redirect nor render component if isAuth is undefined
};
