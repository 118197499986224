import {
	IonButton,
	IonCheckbox,
	IonCol,
	IonContent,
	IonGrid,
	IonList,
	IonLoading,
	IonPage,
	IonRouterLink,
	IonRow,
	IonText,
} from '@ionic/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { FormInput } from '../components/FormInput';
import { HeaderApp } from '../components/HeaderApp';
import { ToastApp } from '../components/ToastApp';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { Logger } from '../utils/logging';
import { cleanupEmail } from '../utils/cleanupEmail';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const logger = new Logger('SignupPage');

interface ISignup {
	email: string;
	password: string;
}

const SignupPage: React.FC<React.PropsWithChildren<{ middleware: AppMiddleware }>> = ({ middleware }) => {
	const history = useHistory();
	const notify = useNotify();
	const [policiesCheck, setPoliciesCheck] = useState<boolean>(false);
	const [showLoading, setShowLoading] = useState<boolean>(false);
	const {
		handleSubmit,
		formState: { errors },
		getValues,
		setValue,
		control,
	} = useForm<ISignup>();
	const handleSignup = async (data: ISignup) => {
		logger.verbose('HV ~ handleSignup ~ data', data);
		setShowLoading(true);

		try {
			await middleware.user.signUp(data.email, data.password);
			history.replace({
				pathname: `/activate-account`,
				state: {
					direction: 'none',
					email: data.email,
				},
			});
		} catch (error: any) {
			logger.exception(error, 'HV ~ handleSignup ~ error', error.message);
			notify.showErrorNotify(error, 0);
		}

		setShowLoading(false);
	};

	return (
		<IonPage className="ion-text-center">
			<HeaderApp goBackTo={'/login'} />
			<IonContent className="ion-padding-horizontal" fullscreen>
				{notify.notifyProps.show && (
					<ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />
				)}
				<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Cargando...'} />
				<IonGrid>
					<h2>Crea tu cuenta</h2>
				</IonGrid>

				<IonList className="ion-padding-horizontal">
					<form onSubmit={handleSubmit(handleSignup)} onBlur={() => cleanupEmail(getValues, setValue)}>
						<FormInput
							label="Correo electrónico"
							name="email"
							control={control}
							errors={errors}
							rules={{
								required: '¡Debe ingresar un email válido!',
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
									message: '¡Email inválido!',
								},
							}}
						/>

						<FormInput
							label="Contraseña"
							name="password"
							control={control}
							errors={errors}
							type="password"
							rules={{
								required: '¡Debe ingresar una contraseña!',
								minLength: {
									value: 8,
									message: '¡Contraseña debe tener al menos 8 caracteres!',
								},
							}}
						/>

						<FormInput
							label="Confirmar contraseña"
							name="confirmPassword"
							control={control}
							errors={errors}
							type="password"
							rules={{
								required: '¡Debe confirmar la contraseña!',
								minLength: {
									value: 8,
									message: '¡Contraseña debe tener al menos 8 caracteres!',
								},
								validate: {
									matchesPreviousPassword: (value) => {
										return getValues('password') === value || '¡Contraseñas deben ser iguales!';
									},
								},
							}}
						/>

						<IonRow className="ion-justify-content-center">
							<IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="6" sizeXl="4">
								<IonRow className="ion-text-start ion-align-items-center ion-padding ion-padding-top">
									<IonText className="pd-privacy-policies">
										Acepto las{' '}
										<IonRouterLink routerLink="/policies" routerDirection="none">
											<span id="lnk-privacy-policies">políticas de privacidad</span>
										</IonRouterLink>
									</IonText>
									<IonCheckbox
										checked={policiesCheck}
										onIonChange={(e) => setPoliciesCheck(e.detail.checked)}
									/>
								</IonRow>
							</IonCol>
						</IonRow>
						<IonRow className="ion-justify-content-center ion-padding">
							<IonCol size="12">
								<IonButton type="submit" className="pd-button-primary" disabled={!policiesCheck}>
									Comenzar
								</IonButton>
							</IonCol>
							<IonCol size="12">
								<IonRouterLink routerLink="/activate-account" routerDirection="none">
									<span id="btn-activate-account">Ya recibí mi email de activación</span>
								</IonRouterLink>
							</IonCol>
						</IonRow>
					</form>
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('SignupPage', 'component')(SignupPage);
