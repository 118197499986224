import { PublicResourceMiddleware } from './PublicResourceMiddleware';
import { AppMiddleware } from './AppMiddleware';
import { ICategoryModel, IDeepCategoryModel } from '../models/ICategoryModel';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from '../utils/logging';

const logger = new Logger('CategoriesMiddleware');

export class CategoriesMiddleware extends PublicResourceMiddleware<ICategoryModel> {
	constructor(private middleware: AppMiddleware) {
		super('categories', '/categories');
	}

	public getDeepCatsWithBookSearch$(searchText: string) {
		return combineLatest([this.middleware.books.all$, this.middleware.categories.all$]).pipe(
			map(([books, cats]) => {
				logger.verbose('Updating categories', books, cats, searchText);

				// 1. Initialize deep cats with empty books field
				const deepCats = cats.map((cat) => {
					return { ...cat, books: [] } as IDeepCategoryModel;
				});

				// 2. Build categories map
				const deepCatsMap = new Map(deepCats.map((c) => [c.id, c]));

				// 3. Add books to cats
				books.forEach((book) => {
					deepCatsMap.get(book.categoryId!)?.books.push(book);
				});

				// 4. Filter out empty cats
				return deepCats.filter((cat) => {
					if (cat.books.length === 0) return false;
					if (!searchText) return true;
					if (
						cat.title.toLowerCase().includes(searchText.toLowerCase()) ||
						cat.books.some((book) => book.title.toLowerCase().includes(searchText.toLowerCase()))
					)
						return true;

					return false;
				});
			}),
		);
	}
}
