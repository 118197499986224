import { AppMiddleware } from './AppMiddleware';
import { APIRequest } from './APIRequest';
import { combineLatest, from, Observable, of } from 'rxjs';
import {
	delay,
	distinctUntilChanged,
	exhaustMap,
	retryWhen,
	shareReplay,
	switchMap,
	takeWhile,
	tap,
} from 'rxjs/operators';
import { Config } from '../utils/config';
import { Logger } from '../utils/logging';

import { Device } from '@capacitor/device';

const logger = new Logger('DeviceMiddleware');

const DEVICEID_KEY = 'deviceid';

export class DeviceMiddleware {
	public readonly deviceId$: Observable<string | null>;

	constructor(private middleware: AppMiddleware) {
		this.deviceId$ = of(localStorage.getItem(DEVICEID_KEY)).pipe(
			switchMap((ldid) => (ldid ? of(ldid) : this.requestDeviceId$())),
			shareReplay(1),
		);
		// Subscribe so that a deviceId is created ASAP in case it does not exist
		this.deviceId$.subscribe((did) => logger.info('deviceId', did));
	}

	/**
	 * Creates an observable that repeatedly attempts to obtain a device id,
	 * and
	 */
	private requestDeviceId$(): Observable<string | null> {
		return combineLatest([this.middleware.user.currentUser$, this.middleware.networkStatus.apiStatus$]).pipe(
			exhaustMap(([user, status]) => {
				logger.verbose('Context changed', user, status);
				return user && status ? from(this.requestDeviceId()) : of(null);
			}),
			retryWhen((errors) =>
				errors.pipe(
					tap((err) => logger.error('requestDeviceId$', err)),
					delay(Config.DEVICEID_REQUEST_INTERVAL_MS),
				),
			),
			distinctUntilChanged(),
			takeWhile((did) => !did, true),
		);
	}

	public async requestDeviceId() {
		logger.verbose('requestDeviceId - starting');

		const { name, uuid } = await this.getDeviceData();

		const response = await APIRequest.post('/devices', { name, uuid });
		logger.info('requestDeviceId - API response', response);
		const newDeviceId = response.data.id;
		localStorage.setItem(DEVICEID_KEY, newDeviceId);
		return newDeviceId;
	}

	private async getDeviceData(): Promise<{ name: string; uuid: string }> {
		const deviceInfo = await Device.getInfo();

		const uuid = (await Device.getId()).uuid;

		let name: string;
		if (deviceInfo.platform === 'web') {
			name = `Navegador (${deviceInfo.manufacturer})`;
		} else {
			name = (({ model, manufacturer, operatingSystem, osVersion }) => [
				manufacturer,
				model,
				operatingSystem,
				osVersion,
			])(deviceInfo)
				.filter((item) => item !== 'unknown')
				.filter((item) => !!item)
				.join(' ');
		}

		logger.info(`created device name ${name} from ${JSON.stringify(deviceInfo)}`);
		return { name, uuid };
	}

	public get deviceId(): string {
		const deviceId = localStorage.getItem(DEVICEID_KEY);
		if (!deviceId) throw new Error('Does not have deviceId');
		return deviceId;
	}
}
