import axios from 'axios';

export const getImageAsArrayBuffer = async (url: string): Promise<ArrayBuffer> => {
	const res = await axios.request({
		url: url,
		method: 'GET',
		withCredentials: false,
		responseType: 'arraybuffer',
	});

	return res.data;
};
