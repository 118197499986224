import { init as initApm } from '@elastic/apm-rum';
import { Config } from './config';

let parts = window.location.hash.split('/');
let pageName = window.location.pathname;

if (parts.length > 0) {
	pageName = parts[1];
}

export const apm = initApm({
	// Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
	serviceName: `${Config.APM_SERVICE_NAME}`,

	// Set custom APM Server URL (default: http://localhost:8200)
	serverUrl: `${Config.ELASTIC_URL}`,

	// Set the service version (required for source map feature)
	serviceVersion: `${Config.COMMIT_SHORT_SHA}`,

	// Set the service environment
	environment: `${Config.APM_ENVIRONMENT}`,

	distributedTracingOrigins: ['http://localhost:3001'],

	propagateTracestate: true,

	pageLoadTransactionName: pageName,
});

export const monitor = (eventName: string, labels: Labels) => {
	const transaction = apm.startTransaction(eventName, 'custom');
	if (labels) transaction?.addLabels(labels);

	return Promise.resolve(transaction).finally(() => transaction?.end());
};
