import { IDBWrapper } from '../utils/IDBWrapper';
import { BehaviorSubject } from 'rxjs';

export class LocalConfigMiddleware {
	private readonly localDB: IDBWrapper;

	public readonly palmRejection$ = new BehaviorSubject<boolean>(false);

	private readonly readyPromise: Promise<void>;

	constructor() {
		this.localDB = new IDBWrapper('localConfig');
		this.readyPromise = new Promise(async (resolve, reject) => {
			await this.loadLocalData();
			resolve();
		});
	}

	public ready() {
		return this.readyPromise;
	}

	private async loadLocalData() {
		this.palmRejection$.next(await this.localDB.get('palmRejection'));
	}

	public async setPalmRejection(value: boolean) {
		await this.localDB.put('palmRejection', value);
		this.palmRejection$.next(value);
	}
}
