import {
	IonBadge,
	IonButton,
	IonCol,
	IonFooter,
	IonGrid,
	IonIcon,
	IonLabel,
	IonRange,
	IonRow,
	IonSkeletonText,
	IonSlide,
	IonSlides,
	IonToolbar,
} from '@ionic/react';
import { caretBackOutline, caretForwardOutline, codeWorkingOutline, gridOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import useWindowSize from '../hooks/useWindowSize';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IBookModel, IPageModel } from '../models/IBookModel';
import { useIsMounted } from './../hooks/useIsMounted';
import styles from './BookCover.module.scss';

export const BookPageSelector: React.FC<
	React.PropsWithChildren<{
		book: IBookModel;
		showPageSelector: boolean;
		currentPage: IPageModel;
		onChangePage: (pageNumber: number) => void;
		setShowPageSelector: (show: boolean) => void;
		middleware: AppMiddleware;
	}>
> = (props) => {
	const { middleware, book, onChangePage, currentPage, setShowPageSelector, showPageSelector } = props;
	const [gridMode, setGridMode] = useState<boolean>(false);
	const [windowWidth] = useWindowSize();
	const [currentPageNumber, setCurrentPageNumber] = useState<number>(currentPage.pageNumber);
	const isMounted = useIsMounted();
	const pageRef = useRef<HTMLDivElement>(null);
	const sliderRef = useRef<HTMLIonSlidesElement>(null);

	useEffect(() => {
		setCurrentPageNumber(currentPage.pageNumber);
	}, [currentPage.pageNumber, showPageSelector]);

	useEffect(() => {
		gridMode && scrollToCurrentPage();
	}, [gridMode]);

	const handleFocusToPage = async () => {
		showPageSelector &&
			(gridMode ? scrollToCurrentPage() : await sliderRef.current?.slideTo(currentPageNumber - 1));
	};

	const changeToGridMode = (gridMode: boolean = true) => {
		if (isMounted.current) {
			setGridMode(gridMode);
		}
	};

	const getIndexSlider = async () => {
		const index = await sliderRef.current?.getActiveIndex();
		setCurrentPageNumber(index ? index + 1 : 0);
	};

	const scrollToCurrentPage = () => {
		const element = pageRef.current;
		element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	const handleChangePage = async (event: CustomEvent) => {
		setCurrentPageNumber(event.detail.value);
		showPageSelector &&
			(gridMode ? scrollToCurrentPage() : await sliderRef.current?.slideTo(event.detail.value - 1));
	};

	const selectPageAndClose = () => {
		onChangePage(currentPageNumber);
		setShowPageSelector(false);
	};

	return (
		<>
			<div
				onClick={() => setShowPageSelector(false)}
				className={showPageSelector ? 'pd-book-page-popover-overlay' : ''}
			/>
			<div
				onTransitionEnd={handleFocusToPage}
				className={`pd-book-page-popover ${!showPageSelector ? 'pd-book-page-popover-hide' : ''}`}
			>
				<IonRange
					id="range-page"
					className="ion-padding-horizontal"
					min={1}
					max={book.totalPages}
					step={1}
					value={currentPageNumber}
					snaps={false}
					onIonChange={handleChangePage}
				>
					<IonLabel slot="start">{1}</IonLabel>
					<IonLabel slot="end">{book.totalPages}</IonLabel>
				</IonRange>

				<IonRow className="ion-text-center ion-no-padding">
					<IonCol size="12" className="ion-no-padding">
						<IonBadge color="medium">
							Página <span id="current-page-number">{currentPageNumber}</span>
						</IonBadge>
						{gridMode ? (
							<IonGrid className="pd-book-page-popover-grid-mode">
								<IonRow className="ion-padding-end">
									{book.pages.length > 0 &&
										book.pages.map((page) => (
											<IonCol
												key={page.id}
												sizeXs="4"
												sizeSm="3"
												sizeMd="3"
												sizeLg="2"
												sizeXl="1"
											>
												<div
													key={page.id}
													ref={page.pageNumber === currentPageNumber ? pageRef : undefined}
													id={`book-page-${page.pageNumber}`}
													className={
														page.pageNumber === currentPageNumber
															? 'pd-book-page-popover-selected'
															: ''
													}
													onClick={() =>
														page.pageNumber === currentPageNumber
															? selectPageAndClose()
															: setCurrentPageNumber(page.pageNumber)
													}
												>
													<PageImage page={page} middleware={middleware} book={book} />
												</div>
											</IonCol>
										))}
								</IonRow>
							</IonGrid>
						) : (
							<IonGrid className="pd-book-page-popover-slide-mode ion-text-center">
								<IonSlides
									id="page-slide-selector"
									key={'slide'}
									ref={sliderRef}
									onIonSlideDidChange={getIndexSlider}
									options={{
										preloadImages: false,
										lazy: true,
										spaceBetween: '1%',
										initialSlide: currentPageNumber - 1,
										slidesPerView: windowWidth / 100,
										centeredSlides: true,
									}}
								>
									{book.pages &&
										book.pages.length > 0 &&
										book.pages.map((page) => (
											<IonSlide
												id={`slide-page-${page.pageNumber}`}
												key={`index-slide-${page.pageNumber}`}
												onClick={() =>
													page.pageNumber === currentPageNumber
														? selectPageAndClose()
														: setCurrentPageNumber(page.pageNumber)
												}
											>
												<PageImage page={page} middleware={middleware} book={book} />
											</IonSlide>
										))}
								</IonSlides>
							</IonGrid>
						)}
						<IonFooter>
							<IonToolbar className="ion-text-center">
								<IonButton
									id="btn-slide-mode"
									size="small"
									className="pd-control-page-button"
									onClick={() => changeToGridMode(false)}
								>
									<IonIcon icon={codeWorkingOutline} />
								</IonButton>
								<IonButton
									id="btn-prev-selector-page"
									size="small"
									className="pd-control-page-button"
									onClick={() =>
										setCurrentPageNumber(
											currentPageNumber === 1 ? currentPageNumber : currentPageNumber - 1,
										)
									}
								>
									<IonIcon icon={caretBackOutline} />
								</IonButton>

								<IonButton
									id="btn-select-page"
									size="small"
									className="pd-control-page-button"
									onClick={() => selectPageAndClose()}
								>
									Ir
								</IonButton>
								<IonButton
									id="btn-next-selector-page"
									size="small"
									className="pd-control-page-button"
									onClick={() =>
										setCurrentPageNumber(
											currentPageNumber === book.totalPages
												? currentPageNumber
												: currentPageNumber + 1,
										)
									}
								>
									<IonIcon icon={caretForwardOutline} />
								</IonButton>
								<IonButton
									id="btn-grid-mode"
									size="small"
									className="pd-control-page-button"
									onClick={() => changeToGridMode(true)}
								>
									<IonIcon icon={gridOutline} />
								</IonButton>
							</IonToolbar>
						</IonFooter>
					</IonCol>
				</IonRow>
			</div>
		</>
	);
};

const PageImage: React.FC<
	React.PropsWithChildren<{
		book: IBookModel;
		page: IPageModel;
		middleware: AppMiddleware;
	}>
> = ({ book, page, middleware }) => {
	const [image, setImage] = useState<string | null>();
	const isMounted = useIsMounted();

	useEffect(() => {
		const url = `${book.url}/${page.image}`;
		url
			? middleware.assets.getThumbnail(url).then((img) => {
					if (isMounted.current) {
						setImage(img);
					}
				})
			: setImage(null);
	}, [book, middleware.assets, page, isMounted]);

	return (
		<IonGrid>
			<IonRow>
				<IonCol size="12">
					<div
						className={styles.BookCover}
						style={{ height: 0, paddingTop: `${(100 * book.heightMM) / book.widthMM}%` }}
					>
						{image ? <img alt="" className="book-card-image" src={image} /> : <IonSkeletonText animated />}
					</div>
					<div className="pd-book-page-popover-number-page">{page.pageNumber}</div>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
