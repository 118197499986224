export const transformSVGStroke = (svg: string) => {
	if (typeof svg === 'string') {
		const strokeParser = new DOMParser();
		const regexNewLine = /\n/g;
		const regexProps =
			/(stroke:\s\w*.\d+.\d*.\d*.)|(stroke-width:\s\d)|(stroke-linecap:\s\w*)|(stroke-linejoin:\s\w*)/g;
		const svgNoNewLines = svg.replace(regexNewLine, '');
		const svgParsed = strokeParser.parseFromString(svgNoNewLines, 'image/svg+xml');
		if (svgParsed.firstChild?.childNodes[0]?.nodeName === 'path') {
			const strokeStyleArray: any = svgParsed
				.evaluate('/g/path/attribute::style', svgParsed, null, XPathResult.ANY_TYPE, null)
				.iterateNext()
				?.nodeValue?.match(regexProps);
			const dataArray: any = svgParsed
				.evaluate('/g/path/attribute::d', svgParsed, null, XPathResult.ANY_TYPE, null)
				.iterateNext()?.nodeValue;
			if (strokeStyleArray && dataArray) {
				const konvaJSON = {
					attrs: {
						stroke: strokeStyleArray[0].split(': ')[1],
						strokeWidth: parseInt(strokeStyleArray[1].split(': ')[1]),
						lineCap: strokeStyleArray[2].split(': ')[1],
						lineJoin: strokeStyleArray[3].split(': ')[1],
						data: dataArray,
					},
					className: 'Path',
				};
				return konvaJSON;
			} else {
				throw new Error('Check the style and data array of the svg');
			}
		} else {
			throw new Error('Svg does not have path node');
		}
	} else {
		throw new Error(`Svg type: ${typeof svg}, the type required to transform: string`);
	}
};

export const transformSVGArea = (svg: string) => {
	if (typeof svg === 'string') {
		const areaParser = new DOMParser();
		const regexNewLine = /\n/g;
		const regexProps =
			/(stroke:\s\w*.\d+.\d*.\d*.)|(stroke-width:\s\d)|(stroke-linecap:\s\w*)|(stroke-linejoin:\s\w*)/g;
		const svgNoNewLines = svg.replace(regexNewLine, '');
		const svgParsed = areaParser.parseFromString(svgNoNewLines, 'image/svg+xml');
		const transfMatrixArray: any = svgParsed
			.evaluate('/g/attribute::transform', svgParsed, null, XPathResult.ANY_TYPE, null)
			.iterateNext()
			?.nodeValue?.split(/[( )]/);
		const areaStyleArray: any = svgParsed
			.evaluate('/g/rect/attribute::style', svgParsed, null, XPathResult.ANY_TYPE, null)
			.iterateNext()
			?.nodeValue?.match(regexProps);
		const areaX: any = svgParsed
			.evaluate('/g/rect/attribute::x', svgParsed, null, XPathResult.ANY_TYPE, null)
			.iterateNext()?.nodeValue;
		const areaY: any = svgParsed
			.evaluate('/g/rect/attribute::y', svgParsed, null, XPathResult.ANY_TYPE, null)
			.iterateNext()?.nodeValue;
		const areaWidth: any = svgParsed
			.evaluate('/g/rect/attribute::width', svgParsed, null, XPathResult.ANY_TYPE, null)
			.iterateNext()?.nodeValue;
		const areaHeight: any = svgParsed
			.evaluate('/g/rect/attribute::height', svgParsed, null, XPathResult.ANY_TYPE, null)
			.iterateNext()?.nodeValue;
		if (transfMatrixArray && areaStyleArray && areaX && areaY && areaWidth && areaHeight) {
			const konvaJSON = {
				attrs: {
					strokeWidth: parseInt(areaStyleArray[0].split(': ')[1]),
					lineCap: areaStyleArray[1].split(': ')[1],
					lineJoin: areaStyleArray[2].split(': ')[1],
					x: parseFloat(transfMatrixArray[5]) + parseFloat(areaX),
					y: parseFloat(transfMatrixArray[6]) + parseFloat(areaY),
					width: parseFloat(areaWidth),
					height: parseFloat(areaHeight),
				},
				className: 'Rect',
			};
			return konvaJSON;
		} else {
			throw new Error('Check the attributes of the rect in the svg string');
		}
	} else {
		throw new Error(`Area svg type: ${typeof svg}, the type required to transform is: string`);
	}
};
