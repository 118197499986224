import { IBookModel } from '../models/IBookModel';
import { PublicResourceMiddleware } from './PublicResourceMiddleware';
import { AppMiddleware } from './AppMiddleware';

export class BooksMiddleware extends PublicResourceMiddleware<IBookModel> {
	constructor(private middleware: AppMiddleware) {
		super('books', '/books', middleware.user.currentUser$);
	}

	public async reloadBook(bookId: number): Promise<IBookModel> {
		return await super.reloadRemoteItem(bookId);
	}

	public async ensureCached(book: IBookModel) {
		if (!book.pages) return false;
		const checkfn = (page: any) => this.middleware.assets.ensureCached(`${book.url}/${page.image}`);
		await Promise.all(book.pages.map(checkfn));
	}

	public async isCached(book: IBookModel) {
		if (!book.pages) return false;
		const checkfn = (page: any) => this.middleware.assets.isCached(`${book.url}/${page.image}`);
		const cached = await Promise.all(book.pages.map(checkfn));
		return cached.every((c) => c);
	}
}
