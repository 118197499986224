import { useState } from 'react';
import { ErrorWrapper } from '../utils/ErrorWrapper';
import { useIsMounted } from './useIsMounted';

interface INotify {
	type?: string;
	show: boolean;
	duration?: number;
	message?: string;
	header?: string;
	closeButon?: boolean;
}

const useNotify = () => {
	const isMounted = useIsMounted();
	const [notifyProps, setNotifyProps] = useState<INotify>({ show: false });

	const onDidDismissNotify = () => {
		if (isMounted.current) {
			setNotifyProps({ show: false });
		}
	};

	const showErrorNotify = (error: any, duration: number = 5000) => {
		const notify = {
			type: 'error',
			duration,
			show: true,
		};

		if (error instanceof ErrorWrapper) {
			setNotifyProps({
				...notify,
				header: error.detail.length > 1 ? error.title : '',
				message: error.detail.length > 1 ? `<br /> - ${error.detail.join('<br /> - ')}` : error.detail[0],
			});
		} else {
			setNotifyProps({
				...notify,
				header: '¡Ha ocurrido un error inesperado!',
				message: error.message,
			});
		}
	};

	const showSuccessNotify = (message: string) => {
		const notify = {
			type: 'success',
			show: true,
			message,
		};

		setNotifyProps(notify);
	};

	const showCustomNotify = (type: string, message: string, duration: number = 0) => {
		const notify = {
			type,
			show: true,
			message,
			duration,
		};

		setNotifyProps(notify);
	};

	return { notifyProps, onDidDismissNotify, showErrorNotify, showSuccessNotify, showCustomNotify };
};

export default useNotify;
