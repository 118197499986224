import React from 'react';
import { IonIcon, IonRange } from '@ionic/react';
import { brush } from 'ionicons/icons';

const PencilSizeContentPopover: React.FC<{
	size:
		| number
		| {
				lower: number;
				upper: number;
		  }
		| undefined;
	sizeHandler: (size: number) => void;
	setShowPopover: (obj: any) => void;
}> = (props) => {
	const { size, sizeHandler, setShowPopover } = props;

	const onSize = (event: CustomEvent) => {
		sizeHandler(event.detail.value);
	};

	return (
		<>
			<IonRange
				min={1}
				max={10}
				value={size}
				onPointerUp={() => setShowPopover({ showPopover: false, event: undefined })}
				onIonChange={onSize}
				style={{ width: '250px' }}
			>
				<IonIcon size="small" slot="start" icon={brush} />
				<IonIcon slot="end" icon={brush} />
			</IonRange>
		</>
	);
};

export default PencilSizeContentPopover;
