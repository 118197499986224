export const getSmoothPathFromPoints = (points: any, correction?: any) => {
	let path = [],
		i;
	let p1 = points[0];
	let p2 = points[1];
	let len = points.length,
		multSignX = 1,
		multSignY = 0,
		manyPoints = len > 2;
	correction = correction || 0;
	if (manyPoints) {
		multSignX = points[2].x < p2.x ? -1 : points[2].x === p2.x ? 0 : 1;
		multSignY = points[2].y < p2.y ? -1 : points[2].y === p2.y ? 0 : 1;
	}
	path.push(`M ${p1.x - multSignX * correction} ${p1.y - multSignY * correction}`);
	for (i = 1; i < len; i++) {
		if (!(p1.x === p2.x) && !(p1.y === p2.y)) {
			const midPoint = {
				x: (p1.x + p2.x) / 2,
				y: (p1.y + p2.y) / 2,
			};
			// p1 is our bezier control point
			// midpoint is our endpoint
			// start point is p(i-1) value.
			path.push(`Q ${p1.x} ${p1.y} ${midPoint.x} ${midPoint.y}`);
		}
		p1 = points[i];
		if (i + 1 < points.length) p2 = points[i + 1];
	}
	if (manyPoints) {
		multSignX = p1.x > points[i - 2].x ? 1 : p1.x === points[i - 2].x ? 0 : -1;
		multSignY = p1.y > points[i - 2].y ? 1 : p1.y === points[i - 2].y ? 0 : -1;
	}
	path.push(`L ${p1.x + multSignX * correction} ${p1.y + multSignY * correction}`);
	return path.join(' ');
};
