import { Logger } from './logging';

const logger = new Logger('resizeImage');

export const resizeImage = async (
	imageData: ArrayBuffer,
	width: number,
	type: string = 'image/jpg',
): Promise<ArrayBuffer | null> => {
	const blob = new Blob([imageData], { type });
	const imgUrl = URL.createObjectURL(blob);

	try {
		const img = document.createElement('img');
		img.src = imgUrl;
		await loaded(img);

		const canvas = document.createElement('canvas');
		//let ctx = canvas.getContext("2d")!;
		//ctx.drawImage(img, 0, 0);

		canvas.width = width;
		canvas.height = (img.height * width) / img.width;
		let ctx = canvas.getContext('2d')!;
		ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

		const blob = await canvasToBlob(canvas, type);
		return await blob!.arrayBuffer();
	} catch (e) {
		logger.error(e);
		return null;
	} finally {
		URL.revokeObjectURL(imgUrl);
	}
};

const canvasToBlob = async (canvas: HTMLCanvasElement, type: string): Promise<Blob | null> => {
	return new Promise((resolve) => {
		canvas.toBlob(resolve, type);
	});
};

const loaded = async (image: HTMLImageElement) => {
	return new Promise((resolve) => {
		image.onload = resolve;
	});
};
