import PouchDB from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUserModel } from '../models/IUserModel';
import { Config } from '../utils/config';
import { Logger } from '../utils/logging';
import { sleep } from '../utils/sleep';
import { AppMiddleware } from './AppMiddleware';

const logger = new Logger('StrokesMiddleware');

PouchDB.plugin(PouchDBFind);

export class StrokesMiddleware {
	protected readonly localDB: PouchDB.Database<{}>;
	protected syncHandler: PouchDB.Replication.Sync<{}> | null = null;

	constructor(protected middleware: AppMiddleware) {
		this.localDB = new PouchDB('strokes', {
			auto_compaction: true,
		});

		const deviceId$ = middleware.device.deviceId$;
		const currentUser$ = middleware.user.currentUser$;
		const networkStatus$ = middleware.networkStatus.apiStatus$;
		const grantsMW = this.middleware.grants;

		const deleteStaleStrokes = async (strokes: PouchDB.Find.FindResponse<{}>, lostAt: Date, deviceId: string) => {
			for (const stroke of strokes.docs) {
				const timestampStroke = new Date((stroke as any).timestamp as number);
				const deviceIdStroke = (stroke as any).deviceId as string;
				if (timestampStroke > lostAt && deviceIdStroke === deviceId) {
					(stroke as any)._deleted = true;
					await this.localDB.put(stroke);
				}
			}
		};

		combineLatest([deviceId$, currentUser$, networkStatus$]).subscribe(async ([deviceId, user, networkStatus]) => {
			if (!deviceId || !networkStatus || !user) {
				if (this.syncHandler) this.stopSync();
				return;
			}

			const grants = await grantsMW.getAllGrants();

			for (const grant of grants) {
				if (deviceId === grant.deviceId) continue;
				const lostAt = await grantsMW.checkGrantLost(grant.bookAuthorizationId);
				if (!lostAt) continue;
				const strokes = await this.localDB.find({
					selector: { $and: [{ authorizationId: grant.bookAuthorizationId }] },
				});
				await deleteStaleStrokes(strokes, lostAt, deviceId);
			}

			if (!this.syncHandler) this.startSync(user!);
		});
	}

	getRemoteDbUrl(dbName: string) {
		// Return the absolute URL of the remote CoachDB, whether Config.API_URL includes host or not
		// The URL needs to be asbsolute, otherwise Pouch considers the DB to be local
		// https://stackoverflow.com/questions/44588403/pouchdb-relative-remote-database-with-couchdb-cloudant
		return new URL(`${Config.API_URL}/../couchdb/${dbName}`, window.location.href).href;
	}

	startSync(user: IUserModel) {
		if (this.syncHandler) throw new Error('Sync already started');

		// TODO: Evaluate if we should use a separate setting for CouchDB URL
		const remoteUrl = this.getRemoteDbUrl(user.couchDbName);
		logger.info('startSync', user, remoteUrl);

		const token = localStorage.getItem('token');

		const remoteOptions = {
			fetch: function (url: any, opts: any) {
				opts.headers.set('Authorization', 'Bearer ' + token);
				return PouchDB.fetch(url, opts);
			},
		};

		const syncOptions = {
			live: true,
			retry: true,
		};

		const remoteDB = new PouchDB(remoteUrl, remoteOptions);

		this.syncHandler = PouchDB.sync(this.localDB, remoteDB, syncOptions)
			.on('change', function (info) {
				// handle change
				logger.verbose('HV ~ sync ~ change', info);
			})
			.on('paused', function (err) {
				// replication paused (e.g. replication up to date, user went offline)
				logger.verbose('HV ~ sync ~ paused', err);
			})
			.on('active', function () {
				// replicate resumed (e.g. new changes replicating, user went back online)
				logger.verbose('HV ~ sync ~ active');
			})
			.on('denied', function (err) {
				// a document failed to replicate (e.g. due to permissions)
				logger.verbose('HV ~ sync ~ denied', err);
			})
			.on('complete', function (info) {
				// handle complete
				logger.verbose('HV ~ sync ~ complete', info);
			})
			.on('error', function (err) {
				// handle error
				logger.verbose('HV ~ sync ~ error', err);
			});
	}

	stopSync() {
		if (!this.syncHandler) throw new Error('Sync not started');
		logger.info('stopSync');
		this.syncHandler.cancel();
		this.syncHandler = null;
	}

	forPage(authorizationId: number, pageId: number): PageStrokesMiddleware {
		return new PageStrokesMiddleware(this.middleware, this.localDB, authorizationId, pageId);
	}

	remoteDB(couchDbName: string): PouchDB.Database<any> {
		// TODO: Evaluate if we should use a separate setting for CouchDB URL
		const remoteUrl = this.getRemoteDbUrl(couchDbName);

		logger.info('HV ~ startSync ~ remoteUrl', remoteUrl);

		const token = localStorage.getItem('token');

		const remoteOptions = {
			fetch: function (url: any, opts: any) {
				opts.headers.set('Authorization', 'Bearer ' + token);
				return PouchDB.fetch(url, opts);
			},
		};

		return new PouchDB(remoteUrl, remoteOptions);
	}

	public ready(): Promise<void> {
		return Promise.resolve();
	}

	public async deleteLocalDb() {
		if (this.localDB) {
			try {
				await this.localDB
					.destroy()
					.then(function () {
						logger.info('Database Deleted');
					})
					.catch(function (err) {
						logger.warn('Could not delete database', err);
					});
			} catch (error: any) {
				logger.exception(error, `¡Ha ocurrido un error al eliminar la base de datos! :: ${error.message}`);
				throw new Error('¡Ha ocurrido un error al eliminar la base de datos!');
			}
		}
	}
}

interface IStrokeInfo {
	_id: string;
	_rev: string;
	_deleted?: boolean;

	undone?: boolean;

	pageId: number;
	authorizationId: number;

	timestamp?: number;

	stroke: any;
}

export class PageStrokesMiddleware {
	// Raw strokes are the objects that are persisted in Pouch
	private readonly rawStrokes$: BehaviorSubject<IStrokeInfo[]> = new BehaviorSubject([] as IStrokeInfo[]);

	// (Non-raw) stokes are just the SVG part of the raw stroke
	public readonly strokes$: Observable<any[]>;

	public readonly canUndo$: Observable<boolean>;
	public readonly canRedo$: Observable<boolean>;

	constructor(
		private middleware: AppMiddleware,
		private localDB: PouchDB.Database<{}>,
		private authorizationId: number,
		private pageId: number,
	) {
		this.strokes$ = this.rawStrokes$.pipe(
			map((ss) =>
				ss
					.filter((s) => s?.stroke && !s._deleted && !s.undone)
					.sort((s, s2) => s.timestamp! - s2.timestamp!)
					.map((s) => s.stroke),
			),
		);

		this.canUndo$ = this.rawStrokes$.pipe(
			map((ss) => ss.filter((s) => s?.stroke && !s._deleted && !s.undone).length > 0),
		);

		this.canRedo$ = this.rawStrokes$.pipe(
			map((ss) => ss.filter((s) => s?.stroke && !s._deleted && s.undone).length > 0),
		);

		this.loadStrokes();
	}

	public ready(): Promise<void> {
		return Promise.resolve();
	}

	private async loadStrokes() {
		const db = this.localDB;

		await db
			.createIndex({
				index: {
					fields: ['authorizationId', 'pageId'],
					name: 'strokeindex3',
					ddoc: 'strokeindex3',
				},
			})
			.then(async () => {
				const resp = await db.find({
					selector: {
						$and: [
							{
								authorizationId: this.authorizationId,
								pageId: this.pageId,
							},
						],
					},
					use_index: 'strokeindex3',
				});

				// Filter for deleted strokes (they show up as undefined)
				const strokes = resp.docs.filter((d) => d) as IStrokeInfo[];
				this.rawStrokes$.next(strokes);
			});
	}

	public async addStroke(stroke: any) {
		try {
			const data = {
				deviceId: this.middleware.device.deviceId,
				timestamp: new Date().getTime(),
				stroke: stroke,
				pageId: this.pageId,
				authorizationId: this.authorizationId,
			};
			await this.localDB.post(data);
			await this.clearRedoStack();
			await this.loadStrokes();
		} catch (error) {
			logger.exception(error, `Error al agregar información: ${error}`);
			throw new Error(`¡Ha ocurrido un error al agregar el documento!`);
		}
	}

	private async deleteStroke(data: IStrokeInfo) {
		try {
			data._deleted = true;
			await this.put(data);
			await this.loadStrokes();
		} catch (error: any) {
			logger.exception(error, `¡Ha ocurrido un error al eliminar documento! :: ${error.message}`);
			throw new Error('¡Ha ocurrido un error al eliminar documento!');
		}
	}

	public async undo() {
		const strokes = this.rawStrokes$.value.filter((s) => !s.undone && !s._deleted);
		if (strokes.length === 0) return;

		const lastStroke = strokes.reduce((s1, s2) => ((s1.timestamp || 0) > (s2.timestamp || 0) ? s1 : s2));

		lastStroke.undone = true;
		await this.put(lastStroke);
		await this.loadStrokes();
	}

	public async redo() {
		const strokes = this.rawStrokes$.value.filter((s) => s.undone && !s._deleted);
		if (strokes.length === 0) return;

		const firstUndoneStroke = strokes.reduce((s1, s2) => ((s1.timestamp || 0) < (s2.timestamp || 0) ? s1 : s2));

		firstUndoneStroke.undone = false;
		await this.put(firstUndoneStroke);
		await this.loadStrokes();
	}

	// Whenever we perform a direct action (not through undo or redo),
	// we must clear the redo stack (ie. the items that were undone).
	private async clearRedoStack() {
		const strokes = this.rawStrokes$.value.filter((s) => s.undone);
		for (let stroke of strokes) {
			stroke._deleted = true;
			await this.put(stroke);
		}
		await this.loadStrokes();
	}

	private async put(stroke: IStrokeInfo) {
		let attempts = 3;

		while (attempts > 0) {
			attempts--;
			try {
				await this.localDB.put(stroke);
				return;
			} catch (e: any) {
				logger.warn(`put: conflict (attempts remaining: ${attempts})`, stroke, e.message);
				await sleep(500);
			}
		}
		console.error('put: failed', stroke);
	}
}
