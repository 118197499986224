import React, { useEffect, useRef, useState } from 'react';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { debounceTime } from 'rxjs/operators';
import { Logger } from '../utils/logging';

const logger = new Logger('BackgroundJobsIndicator');

export const BackgroundJobsIndicator: React.FC<
	React.PropsWithChildren<{
		middleware: AppMiddleware;
	}>
> = ({ middleware }) => {
	const [percent, setPercent] = useState<number | null>(null);
	const currentMax = useRef<number>(0);
	const timer = useRef<any>();

	useEffect(() => {
		const subs = [
			middleware.assets.activeFetchesCount$.pipe(debounceTime(300)).subscribe((count) => {
				logger.verbose('Active fetches', count);

				if (timer.current) {
					clearTimeout(timer.current);
					timer.current = null;
				}

				if (count > currentMax.current) currentMax.current = count;
				if (count > 0) setPercent(((currentMax.current - count) * 100) / currentMax.current);
				else if (currentMax.current > 0) {
					setPercent(100);
					timer.current = setTimeout(() => {
						setPercent(null);
						currentMax.current = 0;
					}, 1000);
				}
			}),
		];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [currentMax, middleware.assets.activeFetchesCount$]);

	return percent !== null ? (
		<div
			style={{
				zIndex: 1,
				position: 'absolute',
				top: 0,
				left: 0,
				width: `${percent}%`,
				height: 3,
				backgroundColor: 'blue',
			}}
		></div>
	) : null;
};
