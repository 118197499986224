import React, { useContext, useEffect, useState } from 'react';
import { IonApp, IonPage, IonTabs } from '@ionic/react';
import './Fullscreen.scss';
import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';
import { IonReactHashRouter } from '@ionic/react-router';

export interface FullScreenProps extends IonicReactProps {
	children?: React.ReactNode | undefined;
}

export const FullscreenContext = React.createContext({
	active: false,
	activate: (fullscreen: boolean) => {},
});

export const IonFullscreenPage: React.FC<FullScreenProps> = (props) => {
	const fullscreen = useContext(FullscreenContext);

	useEffect(() => {
		fullscreen.activate(true);
		return () => {
			fullscreen.activate(false);
		};
	}, [fullscreen]);

	return <IonPage {...props} />;
};

export const IonFullscreenApp: React.FC<FullScreenProps> = (props) => {
	const [fullscreen, setFullscreen] = useState<boolean>(false);

	const fullscreenContext = {
		active: fullscreen,
		activate: (fullscreen: boolean) => setFullscreen(fullscreen),
	};

	return (
		<FullscreenContext.Provider value={fullscreenContext}>
			<IonApp {...props} />
		</FullscreenContext.Provider>
	);
};

export const IonFullscreenTabs: React.FC<FullScreenProps> = (props) => {
	const fullscreen = useContext(FullscreenContext);

	return (
		<IonReactHashRouter>
			<IonTabs className={fullscreen.active ? 'fsa-fullscreen' : ''}>{props.children}</IonTabs>
		</IonReactHashRouter>
	);
};
