// Reactjs
// Ionic
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';
import { AppLauncher, AppLauncherOptions } from '@ionic-native/app-launcher';
import {
	IonButton,
	IonCol,
	IonContent,
	IonFooter,
	IonIcon,
	IonPage,
	IonRow,
	IonText,
	IonToolbar,
	isPlatform,
	useIonModal,
} from '@ionic/react';
import dayjs from 'dayjs';
import Hammer from 'hammerjs';
import {
	arrowRedoCircle,
	arrowUndoCircle,
	brush,
	caretBackOutline,
	caretForwardOutline,
	closeOutline,
	colorPaletteOutline,
	ellipse,
	moveOutline,
} from 'ionicons/icons';
import Konva from 'konva';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import eraserIcon from '../assets/eraser-solid.svg';
// Otras librerias
import { useHistory, useParams } from 'react-router-dom';
import { BookPageSelector } from '../components/BookPageSelector';
import { HeaderApp } from '../components/HeaderApp';
import { ColorModel, PencilColorContentPopover } from '../components/PencilColorContentPopover';
import PencilSizeContentPopover from '../components/PencilSizeContentPopover';
import { ToastApp } from '../components/ToastApp';
import { IonFullscreenPage } from '../helpers/Fullscreen';
// Hooks
import useNotify from '../hooks/useNotify';
import useWindowSize from '../hooks/useWindowSize';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { AssetsMiddleware } from '../middleware/AssetsMiddleware';
import { PageStrokesMiddleware } from '../middleware/StrokesMiddleware';
import { IAcquisitionModel } from '../models/IAcquisitionModel';
import { IAuthorizationModel } from '../models/IAuthorizationModel';
import { IBookModel, IPageModel } from '../models/IBookModel';
import { IProfileModel } from '../models/IProfileModel';
import { IUserModel } from '../models/IUserModel';
import { Config } from '../utils/config';
import { Logger } from '../utils/logging';
import { LRUCache } from '../utils/LRUCache';

import { TextToSpeech } from '@ionic-native/text-to-speech';
import GenericPopover from '../components/GenericPopover';
import ToolsContentPopover from '../components/ToolsContentPopover';
import { createKonvaPath } from '../utils/createKonvaPath';
import { addPoint } from '../utils/simplifyPath';
import { getSmoothPathFromPoints } from '../utils/smoothPath';
import { transformSVGArea } from '../utils/svgToKonva';
const logger = new Logger('BookPage');
// Estados
enum Mode {
	MOVE = 1,
	DRAW,
	ERASE,
}

// Colores
const colors: ColorModel[] = [
	new ColorModel('pen-black', '#000000'),
	new ColorModel('pen-purple', '#800080'),
	new ColorModel('pen-red', '#ff0000'),
	new ColorModel('pen-pink', '#ffc0cb'),
	new ColorModel('pen-orange', '#ffa500'),
	new ColorModel('pen-yellow', '#ffff00'),
	new ColorModel('pen-white', '#ffffff'),
	new ColorModel('pen-green', '#7cfc00'),
	new ColorModel('pen-darkgreen', '#008000'),
	new ColorModel('pen-blue', '#00bfff'),
	new ColorModel('pen-darkblue', '#0000ff'),
	new ColorModel('pen-brown', '#d2691e'),
];

/**
 * Helper class to manage the zoom & pan state
 */
class ZoomPan {
	// State at start of zoom/pan event
	private x0 = 0;
	private y0 = 0;
	private tx0 = 0;
	private ty0 = 0;
	private z0 = 0;

	constructor(
		private stage: Konva.Stage,
		private transCallback: Function,
		private tx: number = 0, // translation, in page coordinates (mm)
		private ty: number = 0,
		private zi: number = 1, // initial zoom so that z=1 fits page width
		private z: number = 1,
		private minz: number = 1, // minimum allowable zoom value
		private maxz: number = 8, // minimum allowable zoom value
		private vw: number = 0, // viewport
		private vh: number = 0,
		private pw: number = 0, // page
		private ph: number = 0
	) {}

	toCanvas() {
		const zt = this.zi * this.z;
		const tx = -this.tx * zt;
		const ty = -this.ty * zt;
		this.stage.scale({ x: zt, y: zt });
		this.stage.position({ x: tx, y: ty });
		this.transCallback();
	}

	/**
	 * Updates for a resized viewport
	 * @param vw Viewport width
	 * @param vh Viewport height
	 * @param pw Page width
	 * @param ph Page height
	 */
	resize(vw: number, vh: number, pw: number, ph: number) {
		[this.vw, this.vh, this.pw, this.ph] = [vw, vh, pw, ph];

		const hzoom = vw / pw;
		const vzoom = vh / ph;

		const min = Math.min(hzoom, vzoom) / hzoom;

		this.z = Math.max(this.z, min);
		this.zi = hzoom;
		this.minz = min;
		this.clamp();
		this.toCanvas();
	}

	panStart(x: number, y: number) {
		[this.x0, this.y0] = [x, y];
		[this.tx0, this.ty0] = [this.tx, this.ty];
	}

	panMove(x: number, y: number) {
		const zt = this.z * this.zi;
		this.tx = this.tx0 - (x - this.x0) / zt;
		this.ty = this.ty0 - (y - this.y0) / zt;
		this.clamp();
		this.toCanvas();
	}

	zoomStart(x: number, y: number) {
		[this.x0, this.y0] = [x, y];
		[this.tx0, this.ty0] = [this.tx, this.ty];
		this.z0 = this.z;
	}

	zoomMove(k: number, x: number, y: number) {
		const zt = this.z0 * this.zi;
		this.z = Math.max(this.minz, Math.min(k * this.z0, this.maxz));
		const ck = this.z / this.z0; // Clamped k

		this.tx = this.tx0 + (x / zt) * (1 - 1 / ck) - (x - this.x0) / zt;
		this.ty = this.ty0 + (y / zt) * (1 - 1 / ck) - (y - this.y0) / zt;

		this.clamp();
		this.toCanvas();
	}

	/**
	 * Zooms in at a specified center.
	 * Shortcut for zoomStart + zoomMove for discrete zoom events (eg. wheel)
	 * @param k Zoom amount
	 * @param x Center X (pixels)
	 * @param y Center Y (pixels)
	 */
	zoomAt(k: number, x: number, y: number) {
		this.zoomStart(x, y);
		this.zoomMove(k, x, y);
	}

	/**
	 * Corrects the parameters so that when we are zoomed out,
	 * the page appears centered in the canvas
	 */
	private clamp() {
		const zt = this.z * this.zi;

		// Viewport size in page coordinates, a.k.a Visible Page Size
		const vpw = this.vw / zt;
		const vph = this.vh / zt;

		if (vpw > this.pw) this.tx = -(vpw - this.pw) / 2;
		else this.tx = Math.max(0, Math.min(this.tx, this.pw - vpw));

		if (vph > this.ph) this.ty = -(vph - this.ph) / 2;
		else this.ty = Math.max(0, Math.min(this.ty, this.ph - vph));
	}
}

class KonvaImageCache extends LRUCache<Konva.Image> {
	constructor(private assetsMW: AssetsMiddleware, private bookWidthMM: number) {
		super(10);
	}

	async fetch(url: string): Promise<Konva.Image | null> {
		logger.verbose('KonvaImageCache.fetch', url);
		const img = await this.assetsMW.getImage(url);
		if (img) {
			logger.verbose('    KonvaImageCache.fetch - got decrypted image');
			return new Promise((resolve, reject) => {
				logger.verbose('    KonvaImageCache.fetch - loading through Konva');
				Konva.Image.fromURL(img, (img: Konva.Image) => {
					const scale = this.bookWidthMM / img.width();
					img.scale({ x: scale, y: scale });
					resolve(img);
				});
			});
		} else {
			return null;
		}
	}
}

// Modals
const DesktopModal: React.FC<
	React.PropsWithChildren<{
		onDismiss: () => void;
	}>
> = ({ onDismiss }) => {
	return (
		<IonPage>
			<IonContent className="ion-text-center">
				<IonRow className="ion-padding">
					<IonCol>
						<h4>PleIQ</h4>
					</IonCol>
				</IonRow>
				<IonRow className="ion-padding">
					<IonCol>
						<IonText>Esta caractéristica sólo se puede usar con el celular</IonText>
					</IonCol>
				</IonRow>
				<IonRow className="ion-padding">
					<IonCol>
						<IonButton className="pd-button-primary" onClick={() => onDismiss()}>
							<IonIcon icon={closeOutline} slot="start" />
							<span>Cancelar</span>
						</IonButton>
					</IonCol>
				</IonRow>
			</IonContent>
		</IonPage>
	);
};

const openModalLaunchPleiQApp = (): Promise<boolean> => {
	return new Promise((resolve) => {
		let rememberResponse = false;
		const alert = document.createElement('ion-alert');
		alert.header = 'Esta actividad lo llevará a la App de PleiQ';
		alert.message = '¿Desea continuar?';
		alert.buttons = [
			{
				text: '¡No, me quedo!',
				handler: () => {
					console.log('~ rememberResponse', rememberResponse);
					if (rememberResponse) localStorage.setItem('usePleiQApp', 'false');
					resolve(false);
				},
			},
			{
				text: '¡Si, vamos!',
				handler: () => {
					console.log('~ rememberResponse', rememberResponse);
					if (rememberResponse) localStorage.setItem('usePleiQApp', 'true');
					resolve(true);
				},
			},
		];
		alert.inputs = [
			{
				type: 'checkbox',
				label: 'Recordar mi respuesta',
				handler: (e) => (rememberResponse = e.checked!),
			},
		];

		alert.onDidDismiss().then(() => resolve(false));

		document.body.appendChild(alert);
		alert.present();
	});
};

const PleiqNotInstalledModal: React.FC<
	React.PropsWithChildren<{
		onDismiss: () => void;
	}>
> = ({ onDismiss }) => {
	return (
		<IonPage>
			<IonContent className="ion-text-center">
				<IonRow className="ion-padding">
					<IonCol>
						<h4>PleIQ no instalado</h4>
					</IonCol>
				</IonRow>
				<IonRow className="ion-padding">
					<IonCol>
						<IonText>Debe instalar Pleiq para utilizar esta característica.</IonText>
					</IonCol>
				</IonRow>
				<IonRow className="ion-padding">
					<IonCol>
						<IonButton className="pd-button-primary" onClick={() => onDismiss()}>
							<IonIcon icon={closeOutline} slot="start" />
							<span>Cancelar</span>
						</IonButton>
					</IonCol>
				</IonRow>
			</IonContent>
		</IonPage>
	);
};

// BookPage
const BookPage: React.FC<React.PropsWithChildren<{ middleware: AppMiddleware }>> = ({ middleware }) => {
	const bookId: number = +useParams<{ bookId: string }>().bookId;
	const authorizationId: number = +useParams<{ authorizationId: string }>().authorizationId;

	// Book
	const [book, setBook] = useState<IBookModel | null>();
	const [grantValid, setGrantValid] = useState<boolean>(false);

	// Canvas
	const [windowWidth, windowHeight] = useWindowSize();
	const [konvaAreas, setKonvaAreas] = useState<any | null>();

	// konvaJS
	const [konvaStage, setKonvaStage] = useState<Konva.Stage | null>();
	const [konvaLayer, setKonvaLayer] = useState<Konva.Layer | null>();
	const [konvaImageLayer, setKonvaImageLayer] = useState<Konva.Layer | null>();
	const [konvaPaths, setkonvaPaths] = useState<any | null>();
	const [konvaImage, setKonvaImage] = useState<Konva.Image | null>();
	const konvaPathRef = useRef<Konva.Path>();
	const isDrawing = useRef<boolean>(false);
	const konvaMatrixRef = useRef<Konva.Transform>();

	const zoomPanRef = useRef<ZoomPan>();

	const hammerRef = useRef<HammerManager>();
	const hammerToolsBtnRef = useRef<HammerManager>();

	// Referencias elementos HTML
	const stageRef = useRef<HTMLDivElement>(null);
	const ionContentRef = useRef<HTMLIonContentElement>(null);
	const toolsBtnRef = useRef<HTMLIonButtonElement>(null);
	const penSizeBtnRef = useRef<HTMLIonButtonElement>(null);
	const colorPickerBtnRef = useRef<HTMLIonButtonElement>(null);

	// Mode & pencil options
	const modeRef = useRef<Mode>(Mode.MOVE);
	const [modeState, setModeState] = useState<Mode>(Mode.MOVE);
	const [previousModeState, setPreviousModeState] = useState<Mode>(Mode.DRAW);
	const [pencilSizeState, setPencilSizeState] = useState<number>(1);
	const [pencilColorState, setPencilColorState] = useState<ColorModel>(colors[0]);
	const palmRejectionRef = useRef<boolean>();

	// Page
	const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
	const [currentPage, setCurrentPage] = useState<IPageModel | null>(null);
	const [hasNextPage, setHasNextPage] = useState<boolean>();
	const [hasPreviousPage, setHasPreviousPage] = useState<boolean>();

	const strokesMWRef = useRef<PageStrokesMiddleware>();
	const [assetsCache, setAssetsCache] = useState<KonvaImageCache>();

	const [canUndo, setCanUndo] = useState<boolean>();
	const [canRedo, setCanRedo] = useState<boolean>();
	const [canEdit, setCanEdit] = useState<boolean>(false);

	const [showPageSelector, setShowPageSelector] = useState<boolean>(false);
	const history = useHistory();

	const [profile, setProfile] = useState<IProfileModel>();
	const [currentUser, setCurrentUser] = useState<IUserModel | null>();
	const [authorization, setAuthorization] = useState<IAuthorizationModel>();
	const [acquisition, setAcquisition] = useState<IAcquisitionModel>();
	const mouseEventTypeRef = useRef<string>('');

	const [showToolsPopover, setShowToolsPopover] = useState<any>({
		showPopover: false,
		event: undefined,
		btnMoveCalled: false,
	});
	const [showPenSizePopover, setshowPenSizePopover] = useState<any>({
		showPopover: false,
		event: undefined,
		btnMoveCalled: false,
	});
	const [showPenColorPopover, setShowPenColorPopover] = useState<any>({
		showPopover: false,
		event: undefined,
		btnMoveCalled: false,
	});

	const notify = useNotify();

	// Modals
	// PleIQ Not Installed
	const handleDismissPleiqNotInstalledModal = () => dismissPleiqNotInstalledModal();

	const [presentPleiqNotInstalledModal, dismissPleiqNotInstalledModal] = useIonModal(PleiqNotInstalledModal, {
		onDismiss: handleDismissPleiqNotInstalledModal,
	});

	const handleDismissDesktopModal = () => dismissDesktopModal();

	const [presentDesktopModal, dismissDesktopModal] = useIonModal(DesktopModal, {
		onDismiss: handleDismissDesktopModal,
	});

	const handleColorPickerChangeComplete = (color: any) => {
		colors.forEach((baseColor: ColorModel) => {
			if (baseColor.color === color.hex) setPencilColorState(baseColor);
		});
	};

	useEffect(() => {
		const subs = [
			middleware.user.currentUser$.subscribe(setCurrentUser),
			middleware.authorizations.all$.subscribe((authorizations: any) => {
				const authorization = authorizations.find(
					(authorization: IAuthorizationModel) => authorization.id === authorizationId
				);
				setAuthorization(authorization);
			}),
			middleware.profiles.all$.subscribe((profs: any) => {
				const profile = profs.find((profile: IProfileModel) => profile.id === authorization?.profileId);
				console.log('profile', profile);
				setProfile(profile);
			}),
			middleware.acquisitions.all$.subscribe((acquisitions: any) => {
				const acquisition = acquisitions.find(
					(acquisition: IAcquisitionModel) => acquisition.id === authorization?.bookAcquisitionId
				);
				setAcquisition(acquisition);
				const acqExpired = dayjs() >= dayjs(acquisition?.expirationDate);
				setCanEdit(!acqExpired);
			}),
		];

		return () => subs.forEach((s) => s.unsubscribe());
	}, [
		bookId,
		authorizationId,
		authorization,
		acquisition,
		middleware.acquisitions,
		middleware.authorizations,
		middleware.profiles,
		middleware.user,
	]);

	// Efecto que sucede al iniciarse el libro
	useEffect(() => {
		logger.verbose('Init');
		setModeState(Mode.MOVE);
		const stageElement: Konva.Stage = new Konva.Stage({
			container: stageRef.current!.id,
			width: window.innerWidth,
			height: window.innerHeight,
		});
		setKonvaStage(stageElement);
		const layer: Konva.Layer = new Konva.Layer(); //canvas in html
		const imgLayer: Konva.Layer = new Konva.Layer(); //canvas in html
		stageElement.add(imgLayer);
		stageElement.add(layer);
		setKonvaLayer(layer);
		setKonvaImageLayer(imgLayer);
		// we add the new element to the db
		stageElement.on('mouseup touchend', () => {
			isDrawing.current = false;
			switch (modeRef.current) {
				case Mode.MOVE:
					return;
				case Mode.DRAW:
				case Mode.ERASE:
					if (!strokesMWRef.current) throw new Error('strokes middleware not initialized');
					if (konvaPathRef.current!.globalCompositeOperation() === 'destination-out') {
						strokesMWRef.current.addStroke({
							type: 'eraser',
							width: konvaPathRef.current!.strokeWidth(),
							path: konvaPathRef.current!.data(),
						});
					} else if (konvaPathRef.current!.globalCompositeOperation() === 'source-over') {
						strokesMWRef.current.addStroke({
							type: 'stroke',
							color: konvaPathRef.current!.stroke(),
							width: konvaPathRef.current!.strokeWidth(),
							path: konvaPathRef.current!.data(),
						});
					} else {
						throw new Error('globalCompositeOperation not supported');
					}
					break;
				default:
					throw new Error(`Mode not handled: ${modeRef.current}`);
			}
		});

		const canvasContainerElement = ionContentRef.current!;
		zoomPanRef.current = new ZoomPan(stageElement, () => {
			konvaMatrixRef.current = stageElement.getAbsoluteTransform().copy().invert();
		});

		// Zoom Logic
		stageElement.on('wheel', (opt: any) => {
			const event = opt.evt as WheelEvent;
			//direction
			let delta;
			switch (event.deltaMode) {
				case WheelEvent.DOM_DELTA_PIXEL:
					delta = event.deltaY / 20;
					break;
				case WheelEvent.DOM_DELTA_LINE:
					delta = event.deltaY;
					break;
				case WheelEvent.DOM_DELTA_PAGE:
					delta = event.deltaY;
					break;
				default:
					delta = Math.sign(event.deltaY);
					break;
			}

			const k = Math.pow(1.05, -delta);
			zoomPanRef.current!.zoomAt(k, event.offsetX, event.offsetY);
		});
		hammerRef.current = new Hammer.Manager(canvasContainerElement);
		const mc = hammerRef.current;
		mc.add(new Hammer.Pan({ enable: true }));
		mc.add(new Hammer.Pinch({ enable: true }));
		mc.add(new Hammer.Press({ enable: true }));
		if (toolsBtnRef.current!) {
			hammerToolsBtnRef.current = new Hammer.Manager(toolsBtnRef.current!);
			hammerToolsBtnRef.current!.add(new Hammer.Press({ enable: true, time: 450 }));
			hammerToolsBtnRef.current!.add(new Hammer.Tap({ enable: true }));
		}
		mc.on('hammer.input', function (event) {
			const pen = (event.srcEvent as any).pointerType === 'pen';
			let drawing: boolean;
			switch (modeRef.current) {
				case Mode.DRAW:
					drawing = true;
					break;
				case Mode.ERASE:
					drawing = true;
					break;
				case Mode.MOVE:
					drawing = !!palmRejectionRef.current && pen;
					break;
				default:
					throw new Error(`Mode not handled: ${modeRef.current}`);
			}
			if (drawing) mc.stop(true);
		});

		mc.on('panstart', function (event) {
			const pen = (event.srcEvent as any).pointerType === 'pen';
			if (pen && modeRef.current === Mode.MOVE && palmRejectionRef.current) mc.stop(true);
			else zoomPanRef.current!.panStart(event.center.x, event.center.y);
		});
		mc.on('panmove', function (event) {
			const pen = (event.srcEvent as any).pointerType === 'pen';
			if (pen && modeRef.current === Mode.MOVE && palmRejectionRef.current) mc.stop(true);
			else zoomPanRef.current!.panMove(event.center.x, event.center.y);
		});
		mc.on('pinchstart', function (event) {
			zoomPanRef.current!.zoomStart(event.center.x, event.center.y);
		});
		mc.on('pinchmove', function (event) {
			zoomPanRef.current!.zoomMove(event.scale, event.center.x, event.center.y);
		});
	}, []);

	const onPointerDown = (event: React.PointerEvent) => {
		(event.target as HTMLDivElement).setPointerCapture(event.pointerId);
	};

	const onPointerUp = (event: React.PointerEvent) => {
		(event.target as HTMLDivElement).releasePointerCapture(event.pointerId);
	};

	//effect when the user is drawing or erasing
	useEffect(() => {
		if (!konvaStage || !konvaLayer) return;
		let newPath = new Konva.Path({ lineCap: 'round', lineJoin: 'round', perfectDrawEnabled: false });
		let rawPathPoints: any = [];
		konvaStage.on('mousedown touchstart', () => {
			if (rawPathPoints.length > 0) rawPathPoints = [];
			switch (modeRef.current) {
				case Mode.MOVE:
					return;
				case Mode.DRAW:
				case Mode.ERASE:
					isDrawing.current = true;
					const pointer: any = konvaStage.getPointerPosition();
					let pointerPos: any = konvaMatrixRef.current!.point(pointer);
					addPoint(pointerPos, rawPathPoints);
					let smoothedPathPoints = getSmoothPathFromPoints(rawPathPoints);
					newPath.data(smoothedPathPoints);
					newPath.globalCompositeOperation(modeRef.current === Mode.DRAW ? 'source-over' : 'destination-out');
					konvaLayer?.add(newPath);
					return;
				default:
					throw new Error(`Mode not handled: ${modeRef.current}`);
			}
		});
		konvaStage.on('mousemove touchmove', () => {
			if (!isDrawing.current) return;
			switch (modeRef.current) {
				case Mode.MOVE:
					return;
				case Mode.DRAW:
				case Mode.ERASE:
					const pointer: any = konvaStage.getPointerPosition();
					let pointerPos: any = konvaMatrixRef.current!.point(pointer);
					addPoint(pointerPos, rawPathPoints);
					let smoothedPathPoints = getSmoothPathFromPoints(rawPathPoints);
					newPath.data(smoothedPathPoints);
					return;
				default:
					throw new Error(`Mode not handled: ${modeRef.current}`);
			}
		});
		konvaPathRef.current = newPath;
	}, [konvaStage, konvaLayer]);

	const handlePointPress = useCallback(
		(obj: any, isCapacitor: boolean) => {
			const type = obj.dataType;
			if (type === 'pleiq' && Config.PLEIQ_INTEGRATION) {
				if (isCapacitor) {
					// trigger our action of obj.data.id
					const options: AppLauncherOptions = {};

					const email = currentUser?.email!;
					const name = [currentUser?.firstName, currentUser?.lastName].filter((text) => text).join('_')!;
					const bookPleIQ = book!.pleiqSlug;
					const markerPleIQ = obj.data.data;
					const code = acquisition?.code?.code!;
					const child = profile!.name?.replace(/\s/g, '_');

					options.uri = `pleiq://app?entry=openbook&&email=${email}&&name=${name}&&book=${bookPleIQ}&&code=${code}&&marker=${markerPleIQ}&&child=${child}`;

					// Check if pleiq is installed
					AppLauncher.canLaunch(options)
						.then(async () => {
							//AppLauncher.launch(options);
							const usePleiQApp = localStorage.getItem('usePleiQApp'); // Mejorar
							if (usePleiQApp === 'false') return;
							if (usePleiQApp === 'true') window.open(options.uri, '_system');
							else {
								const canLaunchPleiQApp = await openModalLaunchPleiQApp();
								if (canLaunchPleiQApp) window.open(options.uri, '_system');
							}
						})
						.catch((error: any) => {
							console.log('~ error', error);
							// Pleiq is not available
							presentPleiqNotInstalledModal();
						});
				} else {
					// Desktop app
					presentDesktopModal();
				}
			} else if (type === 'voice' && Config.VOICE) {
				if (isCapacitor) {
					TextToSpeech.speak({
						text: obj.data.data,
						locale: 'es-CL',
					})
						.then((data) => {
							logger.info(data);
						})
						.catch((err) => {
							logger.error(err);
						});
				} else {
					const u = new SpeechSynthesisUtterance();
					u.text = obj.data.data;
					u.lang = 'es-CL';
					if (speechSynthesis.speaking) speechSynthesis.cancel();
					speechSynthesis.speak(u);
				}
			}
		},
		[book, currentUser, profile, acquisition, presentDesktopModal, presentPleiqNotInstalledModal]
	);

	useEffect(() => {
		const mc = hammerRef.current!;
		const areaEnabled = Config.PLEIQ_INTEGRATION || Config.VOICE;
		if (!areaEnabled || !konvaImageLayer || !mc) return () => mc.off('press');

		mc.on('press', function () {
			const objects = konvaImageLayer.getChildren((node: any) => {
				return node.data;
			});
			objects.forEach((obj: any) => {
				// Its obtains point where it was presses on the screen, then
				// it is verified if it is in one of the areas related to pleiq
				let contains = false;
				const pointer = konvaImageLayer.getRelativePointerPosition();
				const xPointer = pointer.x;
				const yPointer = pointer.y;
				if (
					xPointer >= obj.x() &&
					xPointer <= obj.width() + obj.x() &&
					yPointer >= obj.y() &&
					yPointer <= obj.height() + obj.y()
				)
					contains = true;
				if (!contains) return;
				// It only opens if the application is being used from the cell phone
				const isCapacitor = isPlatform('capacitor');
				handlePointPress(obj, isCapacitor);
			});
		});

		return () => mc.off('press');
	}, [
		book,
		currentUser,
		profile,
		acquisition,
		presentDesktopModal,
		presentPleiqNotInstalledModal,
		konvaImageLayer,
		handlePointPress,
	]);

	// Effect for a long press in the tools button
	useEffect(() => {
		if (toolsBtnRef.current!) {
			hammerToolsBtnRef.current!.on('press', (e) => {
				mouseEventTypeRef.current! = 'press';
				setShowPenColorPopover({ showPopover: false, event: undefined, btnMoveCalled: false });
				setshowPenSizePopover({ showPopover: false, event: undefined, btnMoveCalled: false });
				setShowToolsPopover({ showPopover: true, event: e, btnMoveCalled: false });
			});
			hammerToolsBtnRef.current!.on('pressup', () => {
				mouseEventTypeRef.current! = 'pressup';
			});
		}
	}, []);

	// Grant logic
	useEffect(() => {
		logger.verbose('BookPage - subscribing to isValidForAuth$');
		if (authorizationId !== 0) {
			const subs = [
				middleware.grants.isValidForAuth$(authorizationId).subscribe(async (valid) => {
					logger.info('BookPage - isValidForAuth$', valid);
					if (valid) {
						setGrantValid(true);
					} else {
						try {
							await middleware.grants.requestGrant(authorizationId);
							setGrantValid(true);
						} catch (error) {
							setGrantValid(false);
							history.replace(`/books/withoutgrant/${bookId}/${authorizationId}`, { direction: 'none' });
						}
					}
				}),
			];

			return () => subs.forEach((s) => s.unsubscribe());
		} else {
			setGrantValid(false);
		}
	}, [bookId, authorizationId, history, middleware.grants]);

	useEffect(() => {
		middleware.grants.updateGrants();
	}, [middleware.grants, currentPage]);

	// Track the palm rejection setting
	useEffect(() => {
		const subs = [middleware.localConfig.palmRejection$.subscribe((value) => (palmRejectionRef.current = value))];

		return () => subs.forEach((s) => s.unsubscribe());
	}, [middleware.localConfig.palmRejection$]);

	// Carga el libro cuando el bookId cambia
	useEffect(() => {
		logger.info('Load book');
		const subs = [
			middleware.books.byId$(bookId).subscribe((book) => {
				if (book) {
					setAssetsCache(new KonvaImageCache(middleware.assets, book.widthMM));
					setBook(book);
					logger.info('Got book', book);
				} else {
					setBook(null);
					logger.info('Book is null');
				}
			}),
		];

		return () => subs.forEach((s) => s.unsubscribe());
	}, [bookId, middleware.books, middleware.assets]);

	// Prefetch all pages
	useEffect(() => {
		if (book) middleware.books.ensureCached(book);
	}, [book, middleware.books]);

	// Cambiar a siguiente página
	const onNextPage = () => {
		logger.info('onNextPage', currentPageNumber, book?.pages.length);
		if (book?.pages && currentPageNumber < book.pages.length) setCurrentPageNumber(currentPageNumber + 1);
	};

	// Cambiar a página anterior
	const onPreviousPage = () => {
		logger.info('onPreviousPage', currentPageNumber, book?.pages.length);
		if (book?.pages && currentPageNumber > 1) setCurrentPageNumber(currentPageNumber - 1);
	};

	// Update has[Next/Previous]Page state
	useEffect(() => {
		setHasNextPage(book?.pages && currentPageNumber < book.pages.length);
		setHasPreviousPage(book?.pages && currentPageNumber > 1);
	}, [book?.pages, currentPageNumber]);

	// Activate or deactivate move mode
	const onToggleMoveMode = () => {
		isDrawing.current = false;
		setShowToolsPopover({ showPopover: false, event: undefined, btnMoveCalled: true });
		setShowPenColorPopover({ showPopover: false, event: undefined, btnMoveCalled: false });
		setshowPenSizePopover({ showPopover: false, event: undefined, btnMoveCalled: false });
		setModeState(Mode.MOVE);
	};

	// Activate or deactivate draw mode
	const onToggleDrawMode = () => {
		// Si el código del libro ha expirado el modo será siempre Mode.MOVE
		setPreviousModeState(Mode.DRAW);
		setShowToolsPopover({ showPopover: false, event: undefined, btnMoveCalled: false });
		setModeState(!canEdit ? Mode.MOVE : modeState === Mode.DRAW ? Mode.MOVE : Mode.DRAW);
	};

	// Activate or deactivate erase mode
	const onToggleEraseMode = () => {
		setPreviousModeState(Mode.ERASE);
		setShowToolsPopover({ showPopover: false, event: undefined, btnMoveCalled: false });
		setModeState(!canEdit ? Mode.MOVE : modeState === Mode.ERASE ? Mode.MOVE : Mode.ERASE);
	};

	// Cambiar tamaño pincel
	const onChangeSize = (size: number) => setPencilSizeState(size);

	// Efecto que sucede cuando hay un cambio en el pincel
	useEffect(() => {
		logger.info('useEffect [konvaPathRef, pencilSizeState, pencilColorState]');
		switch (modeState) {
			case Mode.MOVE:
				break;
			case Mode.DRAW:
				konvaPathRef.current!.stroke(pencilColorState.color);
				konvaPathRef.current!.strokeWidth(pencilSizeState);
				break;
			case Mode.ERASE:
				konvaPathRef.current!.strokeWidth(pencilSizeState);
				break;
			default:
				break;
		}
	}, [pencilSizeState, pencilColorState, modeState]);

	useEffect(() => {
		modeRef.current = modeState;
	}, [modeState]);

	// Undo last stroke
	const onUndo = () => {
		if (book && strokesMWRef.current) strokesMWRef.current.undo();
	};

	const onRedo = () => {
		if (book && strokesMWRef.current) strokesMWRef.current.redo();
	};

	const renderPage = useCallback(async () => {
		logger.info('renderPage');
		konvaImageLayer?.removeChildren();
		if (konvaPaths && konvaLayer) {
			konvaLayer.removeChildren();
			konvaPaths.forEach((path: any) => {
				let konvaPath: Konva.Path = Konva.Path.create(path);
				konvaPath.perfectDrawEnabled(false);
				konvaLayer.add(konvaPath);
			});
		}
		if (konvaImage && konvaImageLayer) konvaImageLayer.add(konvaImage);

		if (konvaAreas && konvaImageLayer) konvaAreas.forEach((area: any) => konvaImageLayer.add(area));
	}, [konvaPaths, konvaLayer, konvaImage, konvaImageLayer, konvaAreas]);

	const resizeCanvas = useCallback(
		(stage: Konva.Stage) => {
			logger.verbose('resizeCanvas');
			let w: number, h: number;

			const ionContent = ionContentRef.current as HTMLElement;

			// Keep trying to setup canvas size until container has
			// a non-empty size.
			// This is hackish... we cannot get the container's size
			// before the DOM is rendered, but I don't know how to hook into that
			// Tried useLayoutEffect but it didn't work
			if (!ionContent || ionContent.offsetWidth === 0 || ionContent.offsetHeight === 0) {
				logger.info('Container size not available, retrying later');

				// Set the canvas size to window size in the mean time
				// At least the width should not change
				w = window.innerWidth;
				h = window.innerHeight;

				if (grantValid) window.setTimeout(() => resizeCanvas(stage), 1);
			} else {
				w = ionContent.offsetWidth;
				h = ionContent.offsetHeight;
			}

			logger.info('New canvas size: ', w, h);
			stage.setSize({ width: w, height: h });
			zoomPanRef.current!.resize(w, h, book!.widthMM, book!.heightMM);
		},
		[book, grantValid]
	);

	// Efecto que sucede cuando hay un cambio de dimensiones pantalla en el dispositivo
	useLayoutEffect(() => {
		if (konvaStage && book) resizeCanvas(konvaStage);
	}, [windowWidth, windowHeight, book, resizeCanvas, konvaStage]);

	// Hace el render cuando se actualizan la imagen o los strokes
	useEffect(() => {
		logger.info('useEffect [konvaStage, konvaAreas, konvaStrokes, konvaImage]');
		renderPage();
	}, [konvaStage, konvaLayer, konvaPaths, konvaImageLayer, konvaImage, renderPage]);

	const loadPageImage = useCallback(async () => {
		if (book && assetsCache && currentPage) {
			logger.info('loadPageImage');
			const img = await assetsCache.get(`${book.url}/${currentPage.image}`);
			logger.verbose('    loadPageImage - img');
			setKonvaImage(img);
		} else {
			setKonvaImage(null);
		}
	}, [book, assetsCache, currentPage]);

	// Carga la página cuando hay un cambio de nº de página o de libro
	useEffect(() => {
		loadPageImage();
	}, [loadPageImage]);

	// Carga la página cuando hay un cambio de nº de página o de libro
	useEffect(() => {
		setCurrentPage(book?.pages?.[currentPageNumber - 1] || null);
	}, [currentPageNumber, book]);

	// Carga los strokes cuando hay un cambio de nº de página o de libro
	useEffect(() => {
		if (currentPage && authorizationId) {
			const strokesMW = middleware.strokes.forPage(authorizationId, currentPage.id);
			strokesMWRef.current = strokesMW;
			const subs = [
				strokesMW.strokes$.subscribe((strokes) => {
					setkonvaPaths(strokes.map((stroke) => createKonvaPath(stroke)));
				}),
				strokesMW.canUndo$.subscribe(setCanUndo),
				strokesMW.canRedo$.subscribe(setCanRedo),
			];
			return () => subs.forEach((s) => s.unsubscribe());
		} else {
			setkonvaPaths([]);
			return () => {};
		}
	}, [book, currentPage, authorizationId, middleware.strokes]);

	// Carga las areas cuando hay un cambio de nº de página o de libro
	useEffect(() => {
		const areaEnabled = Config.PLEIQ_INTEGRATION || Config.VOICE;
		if (areaEnabled && currentPage && authorizationId) {
			const areas = currentPage?.areas?.map((area) => {
				let areaParsed = transformSVGArea(area.svg);
				let newArea = Konva.Node.create(areaParsed);
				newArea.data = area.data;
				newArea.dataType = area.type;
				return newArea;
			});
			setKonvaAreas(areas);
		}
	}, [book, currentPage, authorizationId]);

	const showNotification = () => {
		const message = `¡Código de activación se encuentra vencido, este cuaderno solo se podía usar hasta el ${new Date(
			acquisition!.expirationDate
		).toLocaleString('es-CL', { timeZone: 'UTC', year: 'numeric', month: 'numeric', day: 'numeric' })}!`;
		notify.showCustomNotify('danger', message, 5000);
	};

	const disabledButtonStyle = {
		opacity: 0.5,
		pointerEvents: 'all',
	};

	const handleMouseDown = (e: any) => {
		if (!canEdit) showNotification();
		else {
			mouseEventTypeRef.current! = 'mousedown';
			setModeState(previousModeState);
			setShowToolsPopover({ showPopover: true, event: e, btnMoveCalled: false });
			setshowPenSizePopover({ showPopover: false, event: undefined, btnMoveCalled: false });
			setShowPenColorPopover({ showPopover: false, event: undefined, btnMoveCalled: false });
			setTimeout(() => setShowToolsPopover({ showPopover: false, event: undefined, btnMoveCalled: false }), 450);
		}
	};

	const buttonStyle = !canEdit ? disabledButtonStyle : {};

	return (
		<IonFullscreenPage>
			<HeaderApp
				title={`${book && book!.title}${Config.UI_DEBUG_ENTITY_IDS ? ` [p #${currentPage?.id || '...'}]` : ''}`}
				goBackTo={
					authorizationId && middleware.user.currentUser$.value?.pin
						? `/books/leave/${bookId}/${authorizationId}`
						: `/menu/books/detail/${bookId}`
				}
			/>
			<IonContent className="hidden-overflow" ref={ionContentRef}>
				{notify.notifyProps.show && (
					<ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />
				)}
				<div id="container" ref={stageRef} onPointerDown={onPointerDown} onPointerUp={onPointerUp}></div>
			</IonContent>

			<GenericPopover
				eventType={mouseEventTypeRef}
				pos={toolsBtnRef}
				show={showToolsPopover}
				modeState={modeState}
			>
				<ToolsContentPopover
					canEdit={canEdit}
					onToggleDrawMode={onToggleDrawMode}
					onToggleEraseMode={onToggleEraseMode}
					modeState={modeState}
					Mode={Mode}
					showNotification={showNotification}
				/>
			</GenericPopover>

			<GenericPopover pos={penSizeBtnRef} show={showPenSizePopover} modeState={modeState}>
				<PencilSizeContentPopover
					size={pencilSizeState}
					sizeHandler={onChangeSize}
					setShowPopover={setshowPenSizePopover}
				/>
			</GenericPopover>

			<GenericPopover pos={colorPickerBtnRef} show={showPenColorPopover} modeState={modeState}>
				<PencilColorContentPopover
					color={pencilColorState}
					onColorSelected={handleColorPickerChangeComplete}
					setShowPopover={setShowPenColorPopover}
				/>
			</GenericPopover>

			<IonFooter>
				<IonToolbar style={{ textAlign: 'center' }}>
					{!!authorizationId && (
						<>
							<IonButton
								id="btn-move-mode"
								className="pd-control-page-button"
								size="small"
								style={buttonStyle}
								color={modeState === Mode.MOVE ? 'success' : 'primary'}
								onClick={() => {
									!canEdit ? showNotification() : onToggleMoveMode();
								}}
							>
								<IonIcon icon={moveOutline} />
							</IonButton>

							<IonButton
								ref={toolsBtnRef}
								id="btn-tools"
								size="small"
								className="pd-control-page-button"
								style={buttonStyle}
								color={
									modeState === Mode.MOVE ? 'primary' : modeState === Mode.DRAW ? 'success' : 'danger'
								}
								onMouseDown={handleMouseDown}
								onTouchStart={handleMouseDown}
							>
								<IonIcon icon={previousModeState === Mode.ERASE ? eraserIcon : brush} />
							</IonButton>
							<IonButton
								size="small"
								id="btn-undo-stroke"
								className="pd-control-page-button"
								onClick={() => (!canEdit ? showNotification() : onUndo())}
								disabled={!canEdit ? false : !canUndo}
								style={buttonStyle}
							>
								<IonIcon icon={arrowUndoCircle} />
							</IonButton>

							<IonButton
								size="small"
								id="btn-redo-stroke"
								className="pd-control-page-button"
								onClick={() => (!canEdit ? showNotification() : onRedo())}
								disabled={!canEdit ? false : !canRedo}
								style={buttonStyle}
							>
								<IonIcon icon={arrowRedoCircle} />
							</IonButton>
						</>
					)}
					<IonButton
						id="btn-previous-page"
						size="small"
						className="pd-control-page-button"
						onClick={onPreviousPage}
						disabled={!hasPreviousPage}
					>
						<IonIcon icon={caretBackOutline} />
					</IonButton>

					<IonButton
						id="btn-page-number"
						size="small"
						className="pd-control-page-button"
						color="light"
						onClick={() => setShowPageSelector(true)}
					>
						{currentPageNumber}
					</IonButton>

					<IonButton
						id="btn-next-page"
						size="small"
						className="pd-control-page-button"
						onClick={onNextPage}
						disabled={!hasNextPage}
					>
						<IonIcon icon={caretForwardOutline} />
					</IonButton>
					{!!authorizationId && (
						<>
							<IonButton
								ref={penSizeBtnRef}
								id="btn-change-size"
								size="small"
								className="pd-control-page-button"
								style={buttonStyle}
								onClick={(e) => {
									if (!canEdit) showNotification();
									else if (showPenSizePopover.showPopover)
										setshowPenSizePopover({ showPopover: false, event: undefined });
									else {
										mouseEventTypeRef.current = '';
										setShowToolsPopover({ showPopover: false, event: undefined });
										setShowPenColorPopover({ showPopover: false, event: undefined });
										setshowPenSizePopover({ showPopover: true, event: e });
									}
								}}
							>
								<IonIcon
									slot="icon-only"
									icon={ellipse}
									style={{
										fontSize: `${(pencilSizeState as number) + 14}px`,
										position: 'absolute',
									}}
								/>
								<svg />
							</IonButton>

							<IonButton
								ref={colorPickerBtnRef}
								id="btn-color-picker"
								size="small"
								className="pd-control-page-button"
								color={pencilColorState.name}
								style={buttonStyle}
								onClick={(e) => {
									if (!canEdit) showNotification();
									else if (showPenColorPopover.showPopover)
										setShowPenColorPopover({ showPopover: false, event: undefined });
									else {
										mouseEventTypeRef.current = '';
										setShowToolsPopover({ showPopover: false, event: undefined });
										setshowPenSizePopover({ showPopover: false, event: undefined });
										setShowPenColorPopover({ showPopover: true, event: e });
									}
								}}
							>
								<IonIcon icon={colorPaletteOutline} />
							</IonButton>
						</>
					)}
				</IonToolbar>
				{currentPage && book && (
					<BookPageSelector
						book={book}
						onChangePage={setCurrentPageNumber}
						middleware={middleware}
						currentPage={currentPage}
						showPageSelector={showPageSelector}
						setShowPageSelector={setShowPageSelector}
					/>
				)}
			</IonFooter>
		</IonFullscreenPage>
	);
};

export default withTransaction('BookPage', 'component')(BookPage);
