import { IonToast } from '@ionic/react';
import React from 'react';

export const ToastApp: React.FC<React.PropsWithChildren<any>> = ({ notify, onDidDismiss }) => {
	return (
		<IonToast
			color={notify.type === 'error' ? 'danger' : notify.type}
			header={notify.header}
			isOpen={notify.show}
			onDidDismiss={onDidDismiss}
			message={notify.message}
			duration={notify.duration >= 0 ? notify.duration : 3000}
			buttons={[
				{
					text: 'Cerrar',
					role: 'cancel',
					side: 'end',
				},
			]}
		/>
	);
};
