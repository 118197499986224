import React from 'react';
import { IonButton, IonGrid, IonIcon } from '@ionic/react';
import { brush } from 'ionicons/icons';
import eraserIcon from '../assets/eraser-solid.svg';

const ToolsContentPopover: React.FC<{
	canEdit: boolean;
	showNotification: () => void;
	onToggleDrawMode: () => void;
	onToggleEraseMode: () => void;
	modeState: any;
	Mode: any;
}> = (props) => {
	const disabledButtonStyle = {
		opacity: 0.5,
		pointerEvents: 'all',
	};
	const { canEdit, showNotification, onToggleDrawMode, onToggleEraseMode, modeState, Mode } = props;
	return (
		<>
			<IonGrid className="ion-text-center">
				<IonButton
					id="btn-draw-mode"
					className="pd-control-page-button"
					size="small"
					onClick={() => (!canEdit ? showNotification() : onToggleDrawMode())}
					style={!canEdit ? disabledButtonStyle : {}}
					color={modeState === Mode.DRAW ? 'success' : 'primary'}
				>
					<IonIcon icon={brush} />
				</IonButton>

				<IonButton
					id="btn-erase-mode"
					className="pd-control-page-button"
					size="small"
					onClick={() => (!canEdit ? showNotification() : onToggleEraseMode())}
					style={!canEdit ? disabledButtonStyle : {}}
					color={modeState === Mode.ERASE ? 'danger' : 'primary'}
				>
					<IonIcon icon={eraserIcon} />
				</IonButton>
			</IonGrid>
		</>
	);
};

export default ToolsContentPopover;
