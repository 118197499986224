import { CreateAnimation, IonButton, IonCol, IonContent, IonGrid, IonImg, IonRow, IonTitle } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import img from '../assets/welcome.jpg';
import { HeaderApp } from '../components/HeaderApp';
import { IonFullscreenPage } from '../helpers/Fullscreen';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IUserModel } from '../models/IUserModel';
import { Config } from '../utils/config';

const WelcomePage: React.FC<React.PropsWithChildren<{ middleware: AppMiddleware }>> = ({ middleware }) => {
	const history = useHistory();
	const [userData, setUserData] = useState<IUserModel | null>();
	const animationRef = useRef<CreateAnimation>(null);
	const animationBtnRef = useRef<CreateAnimation>(null);

	useEffect(() => {
		let subscription = middleware.user.currentUser$.subscribe((user) => {
			setUserData(user);
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [middleware.user]);

	const handleAnimateImg = () => {
		if (animationRef.current !== null) {
			animationRef.current.setupAnimation({
				duration: 1000,
				fromTo: {
					property: 'transform',
					fromValue: 'scale(0.5)',
					toValue: 'scale(1)',
				},
				easing: 'ease-out',
				play: true,
			});
			setTimeout(() => {
				handleAnimateBtn();
			}, 1500);
		}
	};

	const handleAnimateBtn = () => {
		if (animationBtnRef.current !== null) {
			animationBtnRef.current.setupAnimation({
				duration: 500,
				keyframes: [
					{ offset: 0, transform: 'scale(1)', opacity: '0.8' },
					{ offset: 0.5, transform: 'scale(0.9)', opacity: '1' },
					{ offset: 1, transform: 'scale(1)' },
				],
				play: true,
			});
		}
	};

	const handleRouterLink = (link: string) => {
		history.replace(link, { direction: 'none' });
	};

	return (
		<IonFullscreenPage>
			<HeaderApp />
			<IonContent className="ion-text-center" fullscreen>
				<IonGrid>
					<IonRow className="ion-justify-content-center">
						<IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="6" sizeXl="4" className="pd-welcome-title">
							{userData?.firstName && <b>Hola {userData?.firstName}, </b>}
							<b>¡Bienvenid@ a Caligrafix!</b>
						</IonCol>
					</IonRow>
					<IonRow className="ion-justify-content-center">
						<IonCol sizeXs="10" sizeSm="10" sizeMd="4" sizeLg="4" sizeXl="3">
							<CreateAnimation ref={animationRef}>
								<div className="pd-welcome-img">
									<IonImg src={img} alt="welcome" onIonImgDidLoad={handleAnimateImg} />
								</div>
							</CreateAnimation>
						</IonCol>
					</IonRow>
					<IonRow className="ion-justify-content-center ion-padding">
						<IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="6" sizeXl="6">
							<IonTitle size="small" className="pd-welcome-text">
								Gracias por crear tu cuenta en Caligrafix Papel Digital. Ahora puedes acceder a tus
								cuadernos caligrafix en tu dispositivo. Para ello, busca el código raspe en tu cuaderno
								e ingrésalo en tu librería.
							</IonTitle>
						</IonCol>
					</IonRow>

					<CreateAnimation ref={animationBtnRef}>
						<IonRow className="ion-justify-content-center ion-padding">
							<IonCol size="12">
								<IonButton
									className="pd-button-primary"
									onClick={() => handleRouterLink(Config.UI_START_PAGE)}
								>
									Comenzar
								</IonButton>
							</IonCol>
						</IonRow>
					</CreateAnimation>
				</IonGrid>
			</IonContent>
		</IonFullscreenPage>
	);
};

export default WelcomePage;
