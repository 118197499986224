import {
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonIcon,
	IonImg,
	IonList,
	IonLoading,
	IonPage,
	IonRow,
	IonText,
	IonTitle,
} from '@ionic/react';
import { checkmarkCircleOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import img from '../assets/welcome.jpg';
import { HeaderApp } from '../components/HeaderApp';
import { FormInput } from '../components/FormInput';
import { ToastApp } from '../components/ToastApp';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { Logger } from '../utils/logging';
import { cleanupEmail } from '../utils/cleanupEmail';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const logger = new Logger('RecoveryPasswordPage');

const RecoveryPasswordPage: React.FC<React.PropsWithChildren<{ middleware: AppMiddleware }>> = ({ middleware }) => {
	const history = useHistory();
	const notify = useNotify();

	const [recoveryEmail, setRecoveryEmail] = useState<string>();
	const [showLoading, setShowLoading] = useState<boolean>(false);
	const {
		handleSubmit,
		control,
		formState: { errors },
		getValues,
		setValue,
	} = useForm();

	const handleRecoverPassword = async (data: any) => {
		setShowLoading(true);
		try {
			await middleware.user.recoverPassword(data.email);
			setRecoveryEmail(data.email);
		} catch (error) {
			logger.exception(error, `handleRecoverPassword: ${error}`);
			notify.showErrorNotify(error);
		}
		setShowLoading(false);
	};

	const handleGoToChangePassword = (email: string) => {
		history.replace({
			pathname: `/change-password`,
			state: {
				direction: 'none',
				email,
			},
		});
	};

	const handleRouterLink = (link: string) => {
		history.replace(link, { direction: 'none' });
	};

	return (
		<IonPage>
			<HeaderApp goBackTo={!recoveryEmail ? '/login' : ''} />
			<IonContent className="ion-text-center ion-padding" fullscreen>
				{notify.notifyProps.show && (
					<ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />
				)}
				<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Cargando...'} />
				{recoveryEmail ? (
					<IonGrid>
						<IonRow className="ion-justify-content-center">
							<IonCol size="12">
								<IonGrid>
									<IonIcon className="pd-page-title-icon" icon={checkmarkCircleOutline} />
								</IonGrid>
							</IonCol>
							<IonCol size="12">
								<IonGrid className="ion-padding">
									<b>Verificando que eres tú</b>
								</IonGrid>
							</IonCol>
							<IonCol size="10">
								<IonTitle size="small">
									<span id="result-message">
										Hemos enviado un código al correo electrónico <b>{recoveryEmail}</b>
									</span>
								</IonTitle>
							</IonCol>
						</IonRow>
						<IonRow className="ion-padding">
							<IonCol>
								<IonButton
									id="btn-continue"
									className="pd-button-primary"
									onClick={() => handleGoToChangePassword(recoveryEmail)}
								>
									Continuar
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				) : (
					<IonGrid>
						<IonRow className="ion-justify-content-center">
							<IonCol sizeXs="10" sizeSm="9" sizeMd="8" sizeLg="4" sizeXl="2" className="pd-tutorial-img">
								<IonImg src={img} alt="welcome" />
							</IonCol>
							<IonCol size="12">
								<h5>
									<b>Ayuda con la contraseña</b>
								</h5>
								<IonText className="ion-text-center">
									Escribe la dirección de correo electrónico asociado a tu cuenta.
								</IonText>
							</IonCol>
						</IonRow>

						<IonList className="ion-padding">
							<form
								onSubmit={handleSubmit(handleRecoverPassword)}
								onBlur={() => cleanupEmail(getValues, setValue)}
							>
								<FormInput
									label="Correo electrónico"
									name="email"
									control={control}
									errors={errors}
									rules={{
										required: '¡Debe ingresar un email válido!',
										pattern: {
											value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
											message: '¡Email inválido!',
										},
									}}
								/>
								<IonRow className="ion-justify-content-center">
									<IonCol sizeXs="12" sizeSm="12" sizeMd="8" sizeLg="10" sizeXl="12">
										<IonButton
											id="btn-recovery-password"
											className="pd-button-primary"
											type="submit"
										>
											Recuperar contraseña
										</IonButton>

										<IonButton
											id="btn-change-password"
											className="pd-button-outline"
											onClick={() => handleRouterLink('/change-password')}
										>
											¡Ya tengo un código!
										</IonButton>
									</IonCol>
								</IonRow>
							</form>
						</IonList>
					</IonGrid>
				)}
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('RecoveryPasswordPage', 'component')(RecoveryPasswordPage);
