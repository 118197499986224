import { IonButton, IonCol, IonContent, IonGrid, IonModal, IonPage, IonRow, IonTitle } from '@ionic/react';
import React from 'react';
import { HeaderApp } from '../components/HeaderApp';
import { ToastApp } from '../components/ToastApp';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { Logger } from '../utils/logging';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const logger = new Logger('DeleteAccountPage');

const DeleteAccountPage: React.FC<React.PropsWithChildren<{ middleware: AppMiddleware }>> = ({ middleware }) => {
	const notify = useNotify();
	const modal = React.useRef<HTMLIonModalElement>(null);

	const handleDeleteAccount = async () => {
		try {
			await middleware.user.deleteAccount();
			notify.showSuccessNotify('¡Cuenta eliminada correctamente!');
		} catch (error) {
			logger.exception(error, `SettingsPage.handleDeleteAccount: ${error}`);
			notify.showErrorNotify(error);
			modal.current?.dismiss(null, 'cancel');
			return;
		}
		try {
			await middleware.strokes.deleteLocalDb();
		} catch (error) {
			logger.exception(error, `SettingsPage.handleDeleteAccount: ${error}`);
		}
		modal.current?.dismiss(null, 'delete');
	};

	function dismiss() {
		modal.current?.dismiss(null, 'cancel');
	}

	return (
		<IonPage>
			<HeaderApp goBackTo={'/menu/setting'} />
			<IonContent className="ion-padding-horizontal" fullscreen>
				<IonModal ref={modal} trigger="open-modal" style={{ '--height': 'auto' }}>
					<IonGrid className="ion-text-center">
						<h2>Eliminar cuenta</h2>
						<IonRow className="ion-padding ion-justify-content-center">
							<IonCol size="12" className="ion-padding">
								<IonTitle size="small">
									Todos tus datos serán eliminados. Este proceso no es reversible.
								</IonTitle>
							</IonCol>
						</IonRow>

						<IonRow className="ion-justify-content-center">
							<IonButton className="pd-button-outline" onClick={() => dismiss()}>
								Volver
							</IonButton>
							<IonButton className="pd-button-outline" onClick={() => handleDeleteAccount()}>
								Eliminar
							</IonButton>
						</IonRow>

						<p />
					</IonGrid>
				</IonModal>
				{notify.notifyProps.show && (
					<ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />
				)}
				<IonGrid className="ion-text-center">
					<h2>Eliminar cuenta</h2>
					<IonRow className="ion-padding ion-justify-content-center">
						<IonCol size="12" className="ion-padding">
							<IonTitle size="small">Tu cuenta será eliminada de forma permanente.</IonTitle>
						</IonCol>
					</IonRow>

					<IonRow className="ion-justify-content-center">
						<IonButton className="pd-button-outline-large" id="open-modal">
							Eliminar cuenta
						</IonButton>
					</IonRow>

					<p />
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('DeleteAccountPage', 'component')(DeleteAccountPage);
