import { BehaviorSubject, Observable } from 'rxjs';
import { Config } from '../utils/config';
import { ErrorWrapper } from '../utils/ErrorWrapper';
import { APIRequest } from './APIRequest';
import { map } from 'rxjs/operators';
import { IResource } from '../models/IResource';
import { Logger } from '../utils/logging';

const logger = new Logger('SimpleResourceMiddleware');

export abstract class SimpleResourceMiddleware<T extends IResource> {
	protected readonly _subject$: BehaviorSubject<T[]> = new BehaviorSubject([] as T[]);
	protected readonly urlFragment: string;

	private lastFetchedAt: number = 0;

	protected constructor(urlFragment: string) {
		this.urlFragment = urlFragment;
	}

	protected abstract get(id: number): Promise<T | null>;
	protected abstract getAll(): Promise<T[]>;
	protected abstract put(id: number, data: any): Promise<void>;
	protected abstract delete(id: number): Promise<void>;

	public ready(): Promise<void> {
		return Promise.resolve();
	}

	public get all$(): BehaviorSubject<T[]> {
		this.getRemoteData();
		return this._subject$;
	}

	public byId$(id: number): Observable<T | null> {
		return this.all$.pipe(
			map((items) => {
				return items.find((i) => i.id === id) || null;
			}),
		);
	}

	async getAllData() {
		await this.dispatchLocalData();
		await this.getRemoteData();
	}

	async dispatchLocalData() {
		try {
			const localData = await this.getAllLocalData();
			logger.verbose('HV ~ dispatchLocalData ~ localData********************', localData);
			this._subject$.next(localData!);
		} catch (error) {
			logger.exception(error, 'dispatchLocalData', this.urlFragment);
		}
	}

	protected resetThrottle() {
		this.lastFetchedAt = 0;
	}

	protected async reloadRemoteItem(id: number) {
		const result = await APIRequest.getById(this.urlFragment, id);
		const item = result.data;
		logger.info(() => `reloadRemoteItem [${id}]`, item);
		await this.editLocalData(item.id, item);
		return item;
	}

	async getRemoteData(): Promise<T[]> {
		try {
			const now = Date.now();
			const timeSinceLastFetch = now - this.lastFetchedAt;
			if (timeSinceLastFetch < Config.API_THROTTLE_TIME_MS) {
				logger.info(() => `[${this.urlFragment}] - fetch performed ${timeSinceLastFetch} ms ago, throttling`);
			} else {
				this.lastFetchedAt = now;

				const changed = await this.fetchRemoteData();

				if (changed) await this.dispatchLocalData();
			}
		} catch (error) {
			if ((error as any)?.response?.status === 401)
				logger.info('Authentication error: ', (error as any)?.response?.status);
			else logger.exception(error, 'getRemoteData', this.urlFragment);
		}
		return this._subject$.value;
	}

	protected async fetchRemoteData(): Promise<boolean> {
		logger.verbose(() => `[${this.urlFragment}] - fetching`);
		const apiResponse = await APIRequest.get(this.urlFragment, Config.API_DEFAULT_PAGE_SIZE);
		const localData = await this.getAllLocalData();

		// Para caso /my-account que NO retorna la data dentro de rows
		// Se crea un arreglo con la data para igual todas las respuestas de la Api
		const remoteData: T[] = apiResponse.data.rows || [apiResponse.data];
		logger.info(() => `[${this.urlFragment}] - got remote data`, remoteData);

		// Se actualizan en BD local los documentos que:
		// - No estén localmente
		// - No tienen en la BD local el 'updatedAt'
		// - Tiene localmente 'updatedAt' menor a la Api

		let changed = false;
		const localDataMap = new Map<number, T>(localData.map((item) => [item.id, item]));
		const operations = [];

		// For each remote item:
		// - If there is no local version, add it
		// - If the local version is older, update it
		// - Remove it from the localDataMap map so that we can delete items that do not exist afterward
		for (let remoteItem of remoteData) {
			const localItem = localDataMap.get(remoteItem.id);
			if (localItem) {
				localDataMap.delete(remoteItem.id);

				const localUpdatedAt = new Date(localItem.updatedAt).getTime();
				const remoteUpdatedAt = new Date(remoteItem.updatedAt).getTime();

				const localVersion = localItem._endpointVersion;
				const remoteVersion = remoteItem._endpointVersion;

				if (!localUpdatedAt || remoteUpdatedAt > localUpdatedAt || localVersion !== remoteVersion) {
					// We need to keep attributes that are set locally
					const newItem = { ...localItem, ...remoteItem };
					logger.verbose(() => `[${this.urlFragment}] - update local data`, newItem);
					operations.push(this.put(remoteItem.id, newItem));
					changed = true;
				}
			} else {
				logger.verbose(() => `[${this.urlFragment}] - create local data`, remoteItem);
				operations.push(this.put(remoteItem.id, remoteItem));
				changed = true;
			}
		}

		logger.verbose(() => `[${this.urlFragment}] - remaining local data`, localDataMap);

		// The items left in localDataMap were not in the remote data, we must delete them
		for (let id of localDataMap.keys()) {
			logger.verbose(() => `[${this.urlFragment}] - delete local data`, id);
			operations.push(this.delete(id));
			changed = true;
		}

		await Promise.all(operations);

		return changed;
	}

	/**
	 * Marks all local data as invalid (by removing the updatedAt attribute
	 * so that they will be considered stale during the next fetch).
	 * Does NOT remove the data, only makes sure that it will be updated
	 * during the next fetch.
	 * Also resets the fetch throttle.
	 */
	protected async invalidateLocalData() {
		const allData = await this.getAll();
		await Promise.all(
			allData.map((data) => {
				const { updatedAt, ...newData } = data;
				return this.put(data.id, newData);
			}),
		);
		this.resetThrottle();
	}

	async addData(data: any) {
		if (data?.id) throw new Error('New data should not have an id');
		try {
			const res = await APIRequest.post(this.urlFragment, data);
			await this.addLocalData(res.data.id, res.data);
		} catch (error) {
			logger.exception(error, 'addData', this.urlFragment);
			//throw new Error(`¡Ha ocurrido un error al agregar el documento!`);
			throw new ErrorWrapper(error);
		}
	}

	async editData(id: number, data: any) {
		if (data.id && data.id !== id) throw new Error(`Id mismatch ${data} : ${id}`);
		try {
			await APIRequest.patch(this.urlFragment, id, data);
			await this.editLocalData(id, data);
		} catch (error) {
			//throw new Error('¡Ha ocurrido un error al editar documento!');
			throw new ErrorWrapper(error);
		}
	}

	async deleteData(data: any) {
		try {
			await APIRequest.delete(this.urlFragment, data);
			await this.removeLocalData(data.id);
		} catch (error) {
			logger.exception(error, 'deleteData', this.urlFragment);
			//throw new Error('¡Ha ocurrido un error al eliminar documento!');
			throw new ErrorWrapper(error);
		}
	}

	async getAllLocalData() {
		try {
			return await this.getAll();
		} catch (error) {
			logger.exception(error, 'getAllLocalData', this.urlFragment);
			throw new Error('¡Ha ocurrido un error al obtener documentos!');
		}
	}

	async getLocalData(id: number) {
		try {
			return await this.get(id);
		} catch (error) {
			logger.exception(error, 'getLocalData', this.urlFragment);
			throw new Error('¡Ha ocurrido un error al obtener documentos!');
		}
	}

	async editLocalData(id: number, data: any) {
		if (data.id && data.id !== id) throw new Error(`Id mismatch ${data} : ${id}`);
		try {
			const currentData = await this.get(id);
			await this.put(id, { ...currentData, ...data });
			await this.dispatchLocalData();
		} catch (error) {
			logger.exception(error, 'SimpleResourceMiddleware.editLocalData');
			throw new Error('¡Ha ocurrido un error al editar documento!');
		}
	}

	async addLocalData(id: number, data: any) {
		logger.verbose('HV ~ addLocalData ~ data', data);
		logger.verbose('HV ~ addLocalData ~ id', id);
		if (data?.id && data.id !== id) throw new Error('New data should not have an id');
		try {
			await this.put(id, { id, ...data });
			await this.dispatchLocalData();
		} catch (error) {
			logger.exception(error, 'SimpleResourceMiddleware.addLocalData');
			throw new Error('¡Ha ocurrido un error al editar documento!');
		}
	}

	async removeLocalData(id: number) {
		try {
			await this.delete(id);
			await this.dispatchLocalData();
		} catch (error) {
			logger.exception(error, 'SimpleResourceMiddleware.removeLocalData');
			throw new Error('¡Ha ocurrido un error al editar documento!');
		}
	}
}
