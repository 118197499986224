import { IonButton, IonCol, IonContent, IonGrid, IonList, IonPage, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormInput } from '../components/FormInput';
import { HeaderApp } from '../components/HeaderApp';
import { ToastApp } from '../components/ToastApp';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IUserModel } from '../models/IUserModel';
import { Logger } from '../utils/logging';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const logger = new Logger('AccountPage');

const AccountPage: React.FC<React.PropsWithChildren<{ middleware: AppMiddleware }>> = ({ middleware }) => {
	const notify = useNotify();
	const {
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm<IUserModel>();
	const [user, setUser] = useState<IUserModel | null>();
	useEffect(() => {
		const subs = [
			middleware.user.currentUser$.subscribe((user) => {
				logger.info('got user', user);
				setUser(user);
				reset({
					firstName: user?.firstName || '',
					lastName: user?.lastName || '',
					pin: user?.pin || null,
				});
			}),
		];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [middleware.user, reset]);

	const handleUpdateAccount = async (data: IUserModel) => {
		try {
			await middleware.user.changeData(user!.id, data);
			notify.showSuccessNotify('¡Información actualizada correctamente!');
		} catch (error) {
			logger.exception(error, 'handleUpdateAccount');
			notify.showErrorNotify(error);
		}
	};

	return (
		<IonPage className="ion-text-center">
			<HeaderApp goBackTo="/menu/setting" />
			<IonContent className="ion-padding-horizontal" fullscreen>
				{notify.notifyProps.show && (
					<ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />
				)}
				<IonGrid className="ion-text-center">
					<h2>Mi cuenta</h2>
					<h6>{user?.email}</h6>
					<IonList className="ion-padding">
						<form onSubmit={handleSubmit(handleUpdateAccount)}>
							<FormInput
								label="Nombre"
								name="firstName"
								control={control}
								errors={errors}
								rules={{
									pattern: {
										value: /^[a-zA-ZÁÉÍÓÚáéíóú]+$/,
										message: '¡Debe contener solo letras!',
									},
									required: '¡Debe ingresar un nombre!',
								}}
							/>

							<FormInput
								label="Apellido"
								name="lastName"
								control={control}
								errors={errors}
								rules={{
									pattern: {
										value: /^[a-zA-ZÁÉÍÓÚáéíóú]+$/,
										message: '¡Debe contener solo letras!',
									},
									required: '¡Debe ingresar un apellido!',
								}}
							/>

							<FormInput
								label="PIN"
								name="pin"
								control={control}
								errors={errors}
								type="number"
								rules={{
									pattern: {
										value: /^[0-9]+$/,
										message: '¡Debe ingresar solo números!',
									},
									minLength: {
										value: 4,
										message: '¡PIN debe tener mínimo 4 caracteres!',
									},
									maxLength: {
										value: 6,
										message: '¡PIN debe tener máximo 6 caracteres!',
									},
								}}
							/>

							<IonRow>
								<IonCol>
									<IonButton type="submit" className="pd-button-primary">
										Guardar
									</IonButton>
								</IonCol>
							</IonRow>
						</form>
					</IonList>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('AccountPage', 'component')(AccountPage);
