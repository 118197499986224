import { transformSVGStroke } from './svgToKonva';

export const createKonvaPath = (stroke: any) => {
	if (typeof stroke === 'string') {
		return transformSVGStroke(stroke);
	} else {
		switch (stroke.type) {
			case 'stroke':
				return {
					attrs: {
						stroke: stroke.color,
						strokeWidth: stroke.width,
						data: stroke.path,
						globalCompositeOperation: 'source-over',
						lineCap: 'round',
						lineJoin: 'round',
					},
					className: 'Path',
				};
			case 'eraser':
				return {
					attrs: {
						stroke: 'black',
						strokeWidth: stroke.width,
						data: stroke.path,
						globalCompositeOperation: 'destination-out',
						lineCap: 'round',
						lineJoin: 'round',
					},
					className: 'Path',
				};
			default:
				throw new Error(`"${stroke.type}" stroke type not supported`);
		}
	}
};
