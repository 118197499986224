import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, setupIonicReact } from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import React, { useEffect, useState } from 'react';
import { AppMiddleware } from './middleware/AppMiddleware';
import './theme/theme.scss';
/* Theme variables */
import './theme/variables.css';
import AppUrlListener from './AppUrlListener';
import { BackgroundJobsIndicator } from './components/BackgroundJobsIndicator';
import { libraryOutline, settingsOutline, storefrontOutline } from 'ionicons/icons';
import { Routes } from './Routes';
import SplashPage from './pages/SplashPage';
import { IonFullscreenApp, IonFullscreenTabs } from './helpers/Fullscreen';
import './helpers/test';
import { Logger } from './utils/logging';
import { Config } from './utils/config';

console.log(`COMMIT_SHORT_SHA: ${Config.COMMIT_SHORT_SHA}`);

const logger = new Logger('App');

const middleware = new AppMiddleware();

setupIonicReact();

const App: React.FC = () => {
	const [ready, setReady] = useState<boolean>(false);
	useEffect(() => {
		logger.verbose('init');

		middleware.ready().then(() => {
			setReady(true);
		});
	}, []);

	return (
		<IonFullscreenApp>
			{ready ? (
				<>
					<AppUrlListener middleware={middleware} />
					<BackgroundJobsIndicator middleware={middleware} />

					<IonFullscreenTabs>
						<IonRouterOutlet>
							<Routes middleware={middleware} />
						</IonRouterOutlet>
						<IonTabBar slot="bottom">
							<IonTabButton tab="library" href="/menu/library">
								<IonIcon icon={libraryOutline} />
								<IonLabel>Mis cuadernos</IonLabel>
							</IonTabButton>
							<IonTabButton tab="store" href="/menu/store">
								<IonIcon icon={storefrontOutline} />
								<IonLabel>Catálogo</IonLabel>
							</IonTabButton>
							<IonTabButton tab="setting" href="/menu/setting">
								<IonIcon icon={settingsOutline} />
								<IonLabel>Configuración</IonLabel>
							</IonTabButton>
						</IonTabBar>
					</IonFullscreenTabs>
				</>
			) : (
				<SplashPage />
			)}
		</IonFullscreenApp>
	);
};

export default App;
