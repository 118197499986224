import {
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonItem,
	IonLabel,
	IonList,
	IonLoading,
	IonPage,
	IonRow,
	IonText,
	IonToggle,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { HeaderApp } from '../components/HeaderApp';
import { ToastApp } from '../components/ToastApp';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IAcquisitionModel } from '../models/IAcquisitionModel';
import { IAuthorizationModel } from '../models/IAuthorizationModel';
import { IProfileModel } from '../models/IProfileModel';
import { Logger } from '../utils/logging';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const logger = new Logger('ProfilesBookPage');

const ProfilesBookPage: React.FC<React.PropsWithChildren<{ middleware: AppMiddleware }>> = ({ middleware }) => {
	const history = useHistory();
	const notify = useNotify();

	const acquisitionId = +useParams<{ acquisitionId: string }>().acquisitionId;

	const [profiles, setProfiles] = useState<IProfileModel[] | null>(null);
	const [profilesAuthorized, setProfilesAuthorized] = useState<IProfileModel[]>([]);
	const [acquisition, setAcquisition] = useState<IAcquisitionModel>();
	const [authorizations, setAuthorizations] = useState<IAuthorizationModel[]>([]);
	const [showLoading, setShowLoading] = useState<boolean>(false);

	useEffect(() => {
		const getDetailBook$ = combineLatest([
			middleware.authorizations.all$,
			middleware.profiles.all$,
			middleware.acquisitions.all$,
		]).pipe(
			map(([auths, profiles, acqs]) => {
				logger.info('auths, profiles, acqs', auths, profiles, acqs);
				return acqs
					.map((acq: IAcquisitionModel) => {
						const filteredAuths = auths.filter((auth) => auth.bookAcquisitionId === acquisitionId);
						const filteredProf = profiles.filter((prof) =>
							filteredAuths.some((auth: IAuthorizationModel) => auth.profileId === prof.id),
						);
						return {
							acquisition: acq,
							authorizations: filteredAuths,
							profilesAuthorized: filteredProf,
							profiles: profiles,
						};
					})
					.filter((acq) => acq.acquisition.id === acquisitionId);
			}),
		);

		let subscription = getDetailBook$.subscribe((data: any[]) => {
			logger.verbose('HV ~ subscription ~ data', data);
			if (data[0]) {
				setAcquisition(data[0].acquisition);
				setAuthorizations(data[0].authorizations);
				setProfilesAuthorized(data[0].profilesAuthorized);
				setProfiles(data[0].profiles);
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [acquisitionId, middleware]);

	const handleDeleteItem = (auth: IAuthorizationModel) => {
		middleware.authorizations
			.deleteData(auth)
			.then(() => notify.showSuccessNotify('¡Autorización eliminada exitosamente!'))
			.catch((error) => {
				logger.exception(error, `handleDeleteItem: ${error}`);
				notify.showErrorNotify(error);
			})
			.finally(() => {
				setShowLoading(false);
			});
	};

	const handleAddAuthorization = (bookAcqId: number, profile: IProfileModel) => {
		let data = { profileId: profile.id, bookAcquisitionId: bookAcqId };

		middleware.authorizations
			.addData(data)
			.then(() => {
				notify.showSuccessNotify('¡Cuaderno asociado exitosamente!');
			})
			.catch((error) => {
				logger.exception(error, `handleAddAuthorization: ${error}`);
				notify.showErrorNotify(error);
			})
			.finally(() => {
				setShowLoading(false);
			});
	};

	const handleRouterLink = (link: string) => {
		history.replace(link, { direction: 'none' });
	};

	const handleAuthorization = (isAuthorized: boolean, acquisitionId: number, profile: IProfileModel) => {
		setShowLoading(true);
		if (!isAuthorized) {
			logger.verbose('__test__: creating authorization');
			handleAddAuthorization(acquisitionId, profile);
		} else {
			const authorization = authorizations.filter(
				(auth: IAuthorizationModel) => auth.profileId === profile.id,
			)[0];
			handleDeleteItem(authorization);
		}
	};

	return (
		<IonPage>
			<HeaderApp goBackTo={acquisition && `/menu/books/detail/${acquisition!.bookId}`} />
			<IonContent className="ion-text-center" fullscreen>
				{notify.notifyProps.show && (
					<ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />
				)}
				<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} message={'Cargando...'} />

				<IonGrid>
					{profiles !== null &&
						(profiles.length ? (
							<>
								<h5>
									<b>Perfiles autorizados</b>
								</h5>
								<IonRow>
									<IonCol size="12">Utilizados: {authorizations && authorizations.length}</IonCol>
									<IonCol size="12">
										Disponibles:{' '}
										{acquisition &&
											authorizations &&
											acquisition.authorizationQuotas - authorizations.length}
									</IonCol>
								</IonRow>

								<div className="ion-padding" />
								<IonText className="ion-padding">
									Elige los usuarios que van a tener acceso a este cuaderno
								</IonText>

								<IonRow className="ion-padding ion-justify-content-center">
									<IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="6" sizeXl="4">
										<IonList id="profile-list" lines="none" className="ion-padding">
											{profiles.map((profile: IProfileModel) => {
												const authorization = authorizations.filter(
													(auth: IAuthorizationModel) => auth.profileId === profile.id,
												)[0];

												const isAuthorized = profilesAuthorized.some(
													(profileAuth: IProfileModel) => profile.id === profileAuth.id,
												);
												return (
													<IonItem lines="inset" key={profile.id}>
														<IonLabel>{profile.name}</IonLabel>
														<IonToggle
															id={`profile-${profile.id}`}
															title={`${authorization ? authorization.id : 0}`}
															checked={isAuthorized}
															onClick={() =>
																handleAuthorization(
																	isAuthorized,
																	acquisition!.id,
																	profile,
																)
															}
														/>
													</IonItem>
												);
											})}
										</IonList>
									</IonCol>
								</IonRow>

								<IonButton
									className="pd-button-primary"
									onClick={() => handleRouterLink(`/menu/books/detail/${acquisition!.bookId}`)}
								>
									Volver
								</IonButton>
							</>
						) : (
							<IonRow>
								<IonCol size="12" className="ion-padding">
									<IonText>
										<h5>
											<b>Perfiles autorizados</b>
										</h5>
										<IonGrid className="ion-padding">
											Debes agregar perfiles en la configuración antes de continuar
										</IonGrid>
									</IonText>
								</IonCol>
							</IonRow>
						))}

					<IonRow>
						<IonCol size="12" className="ion-padding">
							<IonButton
								id="btn-add-profiles"
								className="pd-button-outline"
								onClick={() => handleRouterLink(`/menu/profiles/list/${acquisition?.id}`)}
							>
								Agregar Perfiles
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('ProfilesBookPage', 'component')(ProfilesBookPage);
