import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { CatalogCategoryView } from '../components/CatalogCategoryView';
import { CatalogMainView } from '../components/CatalogMainView';
import { HeaderApp } from '../components/HeaderApp';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IDeepCategoryModel } from '../models/ICategoryModel';
import { IUserModel } from '../models/IUserModel';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const CatalogPage: React.FC<
	React.PropsWithChildren<{
		middleware: AppMiddleware;
		isPromptBook?: boolean;
		handleBack?: () => void;
		onSelectedBook?: (bookId?: number | undefined) => void;
	}>
> = ({ middleware, isPromptBook = false, handleBack, onSelectedBook }) => {
	const history = useHistory();
	const params: any = history.location.state;
	const categoryIdFromBookDetail: number = params?.categoryId;
	const [currentUser, setCurrentUser] = useState<IUserModel | null>();
	const [searchText, setSearchText] = useState<string>('');
	const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
	const [categories, setCategories] = useState<IDeepCategoryModel[]>([]);

	useEffect(() => {
		if (categoryIdFromBookDetail) {
			setSelectedCategory(categoryIdFromBookDetail);
		}
	}, [categoryIdFromBookDetail]);

	useEffect(() => {
		const subs = [
			middleware.user.currentUser$.subscribe(setCurrentUser),
			middleware.categories.getDeepCatsWithBookSearch$(searchText || '').subscribe(setCategories),
		];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [middleware.user, middleware.categories, searchText]);

	const handleSelectedBook = (bookId: number) => {
		// if it is provided by PromptBook
		if (onSelectedBook) {
			onSelectedBook(bookId);
		} else {
			history.replace({
				pathname: `/menu/books/detail/${bookId}`,
				state: { direction: 'none', returnToStorePage: true, categoryId: selectedCategory },
			});
		}
	};

	const handleOnBack = () => {
		setSelectedCategory(null);
	};

	return selectedCategory ? (
		<CatalogCategoryView
			onBack={handleOnBack}
			categoryId={selectedCategory}
			middleware={middleware}
			onSelectedBook={handleSelectedBook}
		/>
	) : (
		<IonPage id="page-catalog">
			{!selectedCategory &&
				(isPromptBook ? (
					<HeaderApp
						onBack={handleBack}
						title="Elige el cuaderno que quieres activar"
						background="default"
						searchBar={true}
						onSearchText={setSearchText}
						searchText={searchText}
					/>
				) : (
					<HeaderApp
						title={`${
							currentUser?.firstName || currentUser?.lastName
								? `${currentUser.firstName} ${currentUser.lastName}`
								: 'Bienvenid@'
						}`}
						background={'default'}
						searchBar={true}
						onSearchText={setSearchText}
						searchText={searchText}
					/>
				))}

			<IonContent className="ion-text-start" fullscreen>
				{!selectedCategory && (
					<CatalogMainView
						middleware={middleware}
						categories={categories}
						onSelectedBook={handleSelectedBook}
						onSelectedCategory={setSelectedCategory}
					/>
				)}
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('CatalogPage', 'component')(CatalogPage);
