const getEnv = (name: string, dflt?: string | undefined): string => {
	const fullname = `APP_ENV_${name}`;

	const value = (window as any).app_env[fullname] || dflt;
	if (value === undefined) throw new Error(`Environment variable '${fullname} must be defined`);
	return value;
};

const getEnvBoolean = (name: string, dflt?: boolean | undefined): boolean => {
	const dfltStr = dflt === undefined ? undefined : dflt ? 'true' : '';
	return getEnv(name, dfltStr) === 'true';
};

const getEnvNumber = (name: string, dflt?: number | undefined): number => {
	const dfltStr = dflt === undefined ? undefined : dflt.toString();
	return parseInt(getEnv(name, dfltStr));
};

export class Config {
	public static readonly VERSION: string = getEnv('VERSION', 'undefined');
	public static readonly COMMIT_SHORT_SHA: string = getEnv('COMMIT_SHORT_SHA', 'undefined');
	public static readonly API_URL: string = getEnv('API_URL', '/api');
	public static readonly API_IMAGE_KEY: string = getEnv('API_IMAGE_KEY');
	public static readonly API_DEFAULT_PAGE_SIZE: number = getEnvNumber('API_DEFAULT_PAGE_SIZE', 100);

	public static readonly VOICE: boolean = getEnvBoolean('VOICE', false);
	public static readonly PLEIQ_INTEGRATION: boolean = getEnvBoolean('PLEIQ_INTEGRATION', false);
	public static readonly SHOW_BOOK_AREAS: boolean = getEnvBoolean('SHOW_BOOK_AREAS', false);

	// Defines the time to wait before repeating an API call
	public static readonly API_THROTTLE_TIME_MS: number = getEnvNumber('API_THROTTLE_TIME_MS', 10000);

	public static readonly UI_USE_GOOGLE_AUTHENTICATION: boolean = getEnvBoolean('UI_USE_GOOGLE_AUTHENTICATION', false);

	public static readonly UI_LOGIN_PAGE: string = getEnv('UI_LOGIN_PAGE', '/login');
	public static readonly UI_START_PAGE: string = getEnv('UI_START_PAGE', '/menu/store');
	public static readonly UI_TEST_URL: string = getEnv('UI_TEST_URL', 'http://localhost:3000/#');
	public static readonly UI_TEST_PREFIX: string = getEnv('UI_TEST_PREFIX', 'puppeteer_');

	public static readonly UI_TEST_USE_BOOKS_CODE_FROM_FILE: boolean = getEnvBoolean(
		'UI_TEST_USE_BOOKS_CODE_FROM_FILE',
		true,
	);
	public static readonly UI_TEST_BOOKS_CODE_ENVIRONMENT: string = getEnv('UI_TEST_BOOKS_CODE_ENVIRONMENT', 'local');

	public static readonly UI_DEBUG_ENTITY_IDS: boolean = getEnvBoolean('UI_DEBUG_ENTITY_IDS', false);
	public static readonly UI_DEBUG_DEFAULT_USER: string = getEnv('UI_DEBUG_DEFAULT_USER', '');
	public static readonly UI_DEBUG_DEFAULT_PASSWORD: string = getEnv('UI_DEBUG_DEFAULT_PASSWORD', '');
	public static readonly UI_TEST_USER: string = getEnv('UI_TEST_USER', '');
	public static readonly UI_TEST_PASSWORD: string = getEnv('UI_TEST_PASSWORD', '');

	//check the mailtrap_api_token needed for test
	public static readonly MAILTRAP_API_URL: string = getEnv('MAILTRAP_API_URL', 'https://mailtrap.io/api/v1/');
	public static readonly MAILTRAP_API_TOKEN: string = getEnv(
		'MAILTRAP_API_TOKEN',
		'MAILTRAP_API_TOKEN needed for tests',
	);

	public static readonly COUCHDB_URL: string = getEnv('COUCHDB_URL', 'COUCHDB_URL needed for tests');
	public static readonly API_HEALTHCHECK_INTERVAL_MS: number = getEnvNumber('API_HEALTHCHECK_INTERVAL_MS', 60 * 1000);
	public static readonly DEVICEID_REQUEST_INTERVAL_MS: number = getEnvNumber(
		'DEVICEID_REQUEST_INTERVAL_MS',
		5 * 1000,
	);

	public static readonly LOGGER_CONFIG: string = getEnv('LOGGER_CONFIG', '{"*": "error"}');

	public static readonly ELASTIC_URL: string = getEnv('ELASTIC_URL');
	public static readonly APM_SERVICE_NAME: string = getEnv('APM_SERVICE_NAME');
	public static readonly APM_ENVIRONMENT: string = getEnv('APM_ENVIRONMENT');
}
