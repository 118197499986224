import React from 'react';
import { TwitterPicker } from 'react-color';

export class ColorModel {
	constructor(
		public name: string,
		public color: string,
	) {}
}

const colors = [
	'#000000',
	'#800080',
	'#ff0000',
	'#ffc0cb',
	'#ffa500',
	'#ffff00',
	'#ffffff',
	'#7cfc00',
	'#008000',
	'#00bfff',
	'#0000ff',
	'#d2691e',
];

export const PencilColorContentPopover: React.FC<{
	color: ColorModel;
	onColorSelected: (color: any) => void;
	setShowPopover: (obj: any) => void;
}> = (props) => {
	const { onColorSelected, setShowPopover } = props;
	const onChangeColor = (color: any) => {
		onColorSelected(color);
		setShowPopover({ showPopover: false, event: undefined });
	};

	return (
		<>
			<TwitterPicker
				onChangeComplete={onChangeColor}
				width="100%"
				colors={colors}
				triangle="hide"
				styles={{
					default: {
						input: { display: 'none' },
						hash: { display: 'none' },
						body: {
							paddingLeft: '10px',
							paddingRight: '5px',
							width: '200px',
						},
						swatch: {
							width: '40px',
							height: '40px',
							border: '1px solid grey',
						},
					},
				}}
			/>
		</>
	);
};
