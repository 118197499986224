// Reactjs
import React, { useEffect, useState } from 'react';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { Logger } from '../utils/logging';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';
import { IonContent, IonGrid, IonLoading, IonPage } from '@ionic/react';
import { Redirect } from 'react-router';
import { HeaderApp } from '../components/HeaderApp';

const logger = new Logger('PleiqLoginPage');
const STATES = { LOADING: 'LOADING', ERROR: 'ERROR', SUCCESS: 'SUCCESS' };
const LoginPage: React.FC<{ middleware: AppMiddleware }> = ({ middleware }) => {
	const [result, setResult] = useState<string>();
	const [error, setError] = useState<any>({});
	const [redirect, setRedirect] = useState<string>();

	useEffect(() => {
		const login = async () => {
			try {
				// Remove the # from the URL to read the search params
				const searchParams = new URL(window.location.href.replace('/#', '')).searchParams;
				const token = searchParams.get('token') as string;
				const redirect = searchParams.get('redirect') ?? '/menu/library';
				if (!token) {
					setResult(STATES.ERROR);
					setError({ title: 'URL Inválida', detail: 'Token faltante' });
				} else {
					logger.info(`Received token`);
					await middleware.user.loginWithPleiqToken(token);
					setRedirect(redirect);
					setResult(STATES.SUCCESS);
				}
			} catch (err) {
				logger.error(err);
				setError(err);
				setResult(STATES.ERROR);
			}
		};
		if (result === undefined) {
			//Avoid repeated calls
			setResult(STATES.LOADING);
			login();
		}
	}, [middleware.user, result]);

	return (
		<IonPage>
			<HeaderApp />
			<IonContent className="ion-padding-horizontal" fullscreen>
				<IonGrid className="ion-text-center">
					{result === STATES.LOADING && (
						<IonLoading isOpen={result === STATES.LOADING} message={'Autendicándose con PleIQ...'} />
					)}
					{result === STATES.ERROR && (
						<div>
							<h2>{error?.title ?? ''}</h2>
							<span>{error?.detail ?? ''}</span>
						</div>
					)}
					{result === STATES.SUCCESS && redirect && <Redirect to={redirect} />}
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default withTransaction('PleiqLoginPage', 'component')(LoginPage);
