import { SimpleResourceMiddleware } from './SimpleResourceMiddleware';
import { IUserModel } from '../models/IUserModel';
import { Observable } from 'rxjs';
import { IDBWrapper } from '../utils/IDBWrapper';
import { IResource } from '../models/IResource';

export class PrivateResourceMiddleware<T extends IResource> extends SimpleResourceMiddleware<T> {
	private localDb: IDBWrapper | null = null;

	constructor(dbName: string, urlFragment: string, currentUser$: Observable<IUserModel | null>) {
		super(urlFragment);

		currentUser$.subscribe((user) => {
			this.localDb = user ? new IDBWrapper(`${dbName}.${user.id}`) : null;
			this.resetThrottle();
			this.getAllData();
		});
	}

	async getRemoteData(): Promise<T[]> {
		if (!this.localDb) return [];
		return super.getRemoteData();
	}

	async get(id: number) {
		return this.localDb ? await this.localDb.get(id.toString()) : null;
	}

	async getAll() {
		return this.localDb ? await this.localDb.getAll() : [];
	}

	put(id: number, data: any) {
		if (!this.localDb) throw new Error('Cannot put without a user');
		return this.localDb.put(id.toString(), data);
	}

	delete(id: number) {
		if (!this.localDb) throw new Error('Cannot delete without a user');
		return this.localDb.delete(id.toString());
	}
}
