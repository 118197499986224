import { LOG_LEVELS, Logger } from './logging';

const logger = new Logger('LRUCache', LOG_LEVELS.error);

export abstract class LRUCache<T> {
	private readonly values: Map<string, T> = new Map();
	private readonly activeFetches: Map<string, Promise<T | null>> = new Map();

	protected constructor(private maxEntries: number) {}

	async get(key: string): Promise<T | null> {
		logger.info('get', key);

		const cached = this.values.get(key);
		if (cached) {
			logger.info('    get - hit');
			// Remove and re-insert for LRU eviction
			this.values.delete(key);
			this.values.set(key, cached);
			return cached;
		}

		let activeFetch = this.activeFetches.get(key);
		if (activeFetch) {
			logger.info('    get - miss, fetch already in progress');
			return activeFetch;
		}

		logger.info('    get - miss, fetching');
		activeFetch = this.fetch(key);
		this.activeFetches.set(key, activeFetch);

		const data = await activeFetch;
		this.activeFetches.delete(key);

		if (data) {
			logger.info('    get - got data');
			this.values.set(key, data);
		} else {
			logger.info('    get - got no data');
		}

		while (this.values.size > this.maxEntries) {
			const lruKey = this.values.keys().next().value;
			const lruValue = this.values.get(lruKey);
			this.values.delete(lruKey);
			this.evict(lruKey, lruValue!);
			logger.info('    get - evicting', lruKey);
		}

		return data;
	}

	protected abstract fetch(key: string): Promise<T | null>;
	protected evict(key: string, value: T) {}
}
