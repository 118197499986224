import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import {
	getPlatforms,
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonPage,
	IonRadio,
	IonRadioGroup,
	IonRow,
	IonText,
	isPlatform,
	useIonViewWillEnter,
	useIonModal,
} from '@ionic/react';
import {
	lockClosedOutline,
	scanOutline,
	warningOutline,
	lockOpenOutline,
	cameraOutline,
	closeOutline,
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { BookCover } from '../components/BookCover';
import { FormInput } from '../components/FormInput';
import { HeaderApp } from '../components/HeaderApp';
import { ToastApp } from '../components/ToastApp';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';
import { IBookModel } from '../models/IBookModel';
import { Logger } from '../utils/logging';
import CatalogPage from './CatalogPage';
// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';

const OpenSettingsModal: React.FC<
	React.PropsWithChildren<{
		onDismiss: () => void;
	}>
> = ({ onDismiss }) => {
	const openAppSettings = () => {
		BarcodeScanner.openAppSettings();
		onDismiss();
	};

	return (
		<IonPage>
			<IonContent className="ion-text-center" fullscreen>
				<IonRow className="ion-padding">
					<IonCol>
						<h4>Autorización cámara</h4>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol size="12">
						<IonIcon className="pd-page-title-icon" icon={cameraOutline} />
					</IonCol>
				</IonRow>
				<IonRow className="ion-padding">
					<IonCol>
						<IonText>
							La app no tiene autorización para usar la cámara, que será usada para escanear los códigos
							de barras.
						</IonText>
						<br />
						<IonText>
							Para autorizar el uso de la cámara debe conceder los permisos manualmente desde
							configuraciones.
						</IonText>
					</IonCol>
				</IonRow>
				<IonRow>
					<IonCol></IonCol>
				</IonRow>
				<IonRow className="ion-padding">
					<IonCol>
						<IonButton className="pd-button-primary" onClick={openAppSettings}>
							<IonIcon icon={lockOpenOutline} slot="start" />
							<span>Abrir App Settings</span>
						</IonButton>
						<IonButton className="pd-button-primary" onClick={() => onDismiss()}>
							<IonIcon icon={closeOutline} slot="start" />
							<span>Cancelar</span>
						</IonButton>
					</IonCol>
				</IonRow>
			</IonContent>
		</IonPage>
	);
};

const logger = new Logger('ActivateBookPage');

enum State {
	PROMPT_CODE = 1,
	PROMPT_BOOK,
	CONFIRM_FOR_GENERIC,
	CONFIRM_FOR_MATCH,
	CONFIRM_FOR_MISMATCH,
}

const ActivateBookPage: React.FC<
	React.PropsWithChildren<{
		middleware: AppMiddleware;
	}>
> = ({ middleware }) => {
	const notify = useNotify();
	const history = useHistory();

	const requestedBookIdParam = useParams<{ bookId: string | undefined }>().bookId;

	const [requestedBookId, setRequestedBookId] = useState<number | undefined>(
		requestedBookIdParam ? +requestedBookIdParam : undefined,
	);
	const [code, setCode] = useState<string>('');
	const [state, setState] = useState<State>(State.PROMPT_CODE);
	const [bookChangeRequested, setBookChangeRequested] = useState<boolean>(false);
	const [barCodeScanner, setBarCodeScanner] = useState<boolean>(false);

	// The bookId associated with the code
	// null means the code is generic
	// undefined means not set yet
	const [codeBookId, setCodeBookId] = useState<number | null | undefined>();
	//const [selectedCategory, setSelectedCategory] = useState<number | null>(null);

	const reset = () => {
		logger.info('reset', requestedBookIdParam);
		setRequestedBookId(requestedBookIdParam ? +requestedBookIdParam : undefined);
		setCodeBookId(undefined);
		setCode('');
	};

	const stopScan = async () => {
		await BarcodeScanner.showBackground();
		await BarcodeScanner.stopScan();
		setBarCodeScanner(false);
	};

	useEffect(() => {
		return () => {
			if (isPlatform('capacitor')) stopScan();
		};
	}, []);

	// Update state according to current values
	useEffect(() => {
		console.log('update state', code, codeBookId, requestedBookId);
		if (!code) {
			setState(State.PROMPT_CODE);
		} else {
			if ((!codeBookId && !requestedBookId) || bookChangeRequested) {
				setState(State.PROMPT_BOOK);
			} else {
				if (!codeBookId) {
					setState(State.CONFIRM_FOR_GENERIC);
				} else if (!requestedBookId || codeBookId === requestedBookId) {
					setState(State.CONFIRM_FOR_MATCH);
				} else {
					setState(State.CONFIRM_FOR_MISMATCH);
				}
			}
		}
	}, [code, codeBookId, requestedBookId, bookChangeRequested]);

	const handleBarCodeScanner = (state: boolean) => {
		setBarCodeScanner(state);
	};

	const handleCodeEntered = async (code: string) => {
		try {
			code = code.trim();
			const codeBookId = await middleware.acquisitions.checkBookCode(code);
			setCode(code);
			setCodeBookId(codeBookId);

			logger.info(`handleCodeEntered - codeBookId=${codeBookId}, requestedBookId=${requestedBookId}`);
		} catch (error) {
			notify.showErrorNotify(error, 0);
			setCode('');
			setCodeBookId(undefined);
		}
	};

	const handleChangeBook = () => {
		logger.verbose('HV ~ handleChangeBook ~ handleChangeBook');
		setRequestedBookId(undefined);
		setBookChangeRequested(true);
	};

	const handleSelectedBook = (bookId: number | undefined) => {
		console.log('HV ~ handleSelectedBook ~ bookId', bookId);
		setRequestedBookId(bookId);
		setBookChangeRequested(false);
	};

	const handleConfirmed = async (bookId: number, force: boolean = false) => {
		try {
			const acq = await middleware.acquisitions.activateNewBook(bookId, code, force);
			const url = `/menu/books/profiles/${acq.id}`;
			history.replace(url, { direction: 'none' });
		} catch (error) {
			notify.showErrorNotify(error);
		}
	};

	const handleBack = () => {
		switch (state) {
			case State.PROMPT_CODE:
				const isCapacitor = isPlatform('capacitor');

				if (barCodeScanner && isCapacitor) {
					stopScan();
				} else {
					const url = requestedBookId ? `/menu/books/detail/${requestedBookIdParam}` : '/menu/library';
					history.replace(url, { direction: 'none' });
				}
				break;
			case State.CONFIRM_FOR_GENERIC:
			case State.CONFIRM_FOR_MATCH:
			case State.CONFIRM_FOR_MISMATCH:
			case State.PROMPT_BOOK:
				reset();
				break;
			default:
				throw new Error(`Unknown state: ${state}`);
		}
	};

	if (barCodeScanner) {
		return (
			<IonPage>
				<HeaderApp onBack={handleBack} background="linear-gradient(90.89deg, #ff9d42 -2.08%, #ea7000 98%)" />
				<IonContent style={{ '--background': 'rgba(0, 0, 0, 0.2)' }} fullscreen>
					<div className="scan-box"></div>
				</IonContent>
			</IonPage>
		);
	}

	return (
		<>
			{/* need to display outside of <ionPage>  */}
			{state === State.PROMPT_BOOK ? (
				<CatalogPage
					handleBack={handleBack}
					onSelectedBook={handleSelectedBook}
					isPromptBook={true}
					middleware={middleware}
				/>
			) : (
				<IonPage>
					<HeaderApp onBack={handleBack} />
					{notify.notifyProps.show && (
						<ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />
					)}
					<IonContent className="ion-text-center" fullscreen>
						{state === State.PROMPT_CODE && (
							<PromptCode onEnter={handleCodeEntered} onBarCodeScanner={handleBarCodeScanner} />
						)}
						{state === State.CONFIRM_FOR_GENERIC && (
							<ConfirmForGeneric
								middleware={middleware}
								bookId={requestedBookId!}
								onConfirm={() => handleConfirmed(requestedBookId!)}
								onChangeBook={handleChangeBook}
							/>
						)}
						{state === State.CONFIRM_FOR_MATCH && (
							<ConfirmForMatch
								middleware={middleware}
								bookId={codeBookId!}
								onConfirm={() => handleConfirmed(codeBookId!)}
								onChangeBook={handleChangeBook}
								onCancel={handleBack}
							/>
						)}
						{state === State.CONFIRM_FOR_MISMATCH && (
							<ConfirmForMismatch
								middleware={middleware}
								codeBookId={codeBookId!}
								requestedBookId={requestedBookId!}
								onConfirm={(bookId) => handleConfirmed(bookId, true)}
								onChangeBook={handleChangeBook}
							/>
						)}
					</IonContent>
				</IonPage>
			)}
		</>
	);
};

const PromptCode: React.FC<
	React.PropsWithChildren<{
		onEnter: (code: string) => void;
		onBarCodeScanner: (state: boolean) => void;
	}>
> = ({ onEnter, onBarCodeScanner }) => {
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm();
	const isCapacitor = isPlatform('capacitor');

	useIonViewWillEnter(() => {
		// Para setear activationCode al cambiar de tabs
		reset({ code: '' });
	});

	useEffect(() => {
		const platforms = getPlatforms();
		logger.info('PromptCode - platforms', platforms);
	}, []);

	const handleDismissModal = () => {
		dismissModal();
	};

	const [presentModal, dismissModal] = useIonModal(OpenSettingsModal, {
		onDismiss: handleDismissModal,
	});

	const checkPermission = async () => {
		// check if user already granted permission
		const status = await BarcodeScanner.checkPermission({ force: false });

		console.log('ActivateBookPage', status);
		if (status.granted) {
			// user granted permission
			return true;
		}

		if (status.denied) {
			// user denied permission
			return false;
		}

		if (status.asked) {
			// system requested the user for permission during this call
			// only possible when force set to true
		}

		if (status.neverAsked) {
			// user has not been requested this permission before
			// it is advised to show the user some sort of prompt
			// this way you will not waste your only chance to ask for the permission
		}

		if (status.restricted || status.unknown) {
			// ios only
			// probably means the permission has been denied
			return false;
		}

		// user has not denied permission
		// but the user also has not yet granted the permission
		// so request it
		const statusRequest = await BarcodeScanner.checkPermission({ force: true });

		if (statusRequest.asked) {
			// system requested the user for permission during this call
			// only possible when force set to true
		}

		if (statusRequest.granted) {
			// the user did grant the permission now
			return true;
		}

		// user did not grant the permission, so he must have declined the request
		return false;
	};

	const handleScan = async () => {
		logger.verbose('PromptCode - scanning');
		const permission = await checkPermission();
		if (!permission) {
			presentModal();
			return;
		}
		await BarcodeScanner.hideBackground();
		onBarCodeScanner(true);

		const data = await BarcodeScanner.startScan();
		await BarcodeScanner.showBackground();
		onBarCodeScanner(false);
		logger.info('PromptCode - scanned', data);
		if (data.hasContent) {
			const code = data.content!;
			reset({ code });
			onEnter(code);
		}
	};

	const handleEnter = async (text: string) => {
		text = text.replace(/[\s_-]/g, '');
		text = text.toLowerCase();
		onEnter(text);
	};

	return (
		<IonGrid>
			<IonRow className="ion-padding">
				<IonCol size="12">
					<IonIcon className="pd-page-title-icon" icon={lockClosedOutline} />
				</IonCol>
				<IonCol size="12">
					<IonText>
						<h5>
							<b>
								{isCapacitor ? (
									'Escanea o ingresa tu código de activación'
								) : (
									<span id="title-add-code">Ingresa tu código de activación</span>
								)}
							</b>
						</h5>
					</IonText>
					<br />
					<IonText>El código de activación está ubicado en la contraportada del cuaderno</IonText>
				</IonCol>
			</IonRow>

			{isCapacitor && (
				<IonRow className="ion-padding">
					<IonCol>
						<IonButton className="pd-button-primary" onClick={handleScan}>
							<IonIcon icon={scanOutline} slot="start" />
							<span>Escanear</span>
						</IonButton>
						<p />
					</IonCol>
				</IonRow>
			)}

			<IonList className="ion-padding">
				<form onSubmit={handleSubmit((data) => handleEnter(data.code))}>
					<FormInput
						label="Código de activación"
						name="code"
						control={control}
						errors={errors}
						type="text"
						rules={{
							required: '¡Debe ingresar un código!',
						}}
					/>

					<IonRow className="ion-padding">
						<IonCol>
							<IonButton id="btn-activate-book" className="pd-button-primary" type="submit">
								Activar
							</IonButton>
						</IonCol>
					</IonRow>
				</form>
			</IonList>
		</IonGrid>
	);
};

const ConfirmForGeneric: React.FC<
	React.PropsWithChildren<{
		middleware: AppMiddleware;
		bookId: number;
		onConfirm: () => void;
		onChangeBook: () => void;
	}>
> = ({ middleware, bookId, onConfirm, onChangeBook }) => {
	const [book, setBook] = useState<IBookModel | null>(null);

	useEffect(() => {
		const subs = [middleware.books.byId$(bookId).subscribe(setBook)];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [bookId, middleware.books]);

	return (
		<IonGrid>
			<IonRow className="ion-padding ion-justify-content-center">
				<IonCol sizeXl="6" sizeLg="8" sizeMd="12">
					<h5>
						<b>
							<span id="confirmation-message">Estás a punto de activar este cuaderno:</span>
						</b>
					</h5>
					<div>{book ? book.title : typeof book}</div>
					{book && <BookCoverWraper book={book} middleware={middleware} />}

					<h5>
						El código que ingresaste te permite activar cualquier cuaderno. Si este es el cuaderno correcto,
						selecciona Activar. De lo contrario, puedes elegir otro cuaderno.
					</h5>
				</IonCol>

				<IonCol size="12">
					<IonButton id="btn-activate-book" className="pd-button-primary" onClick={onConfirm}>
						Activar
					</IonButton>
				</IonCol>
				<IonCol size="12">
					<IonButton id="btn-select-book" className="pd-button-outline" onClick={onChangeBook}>
						Elegir otro cuaderno
					</IonButton>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

const ConfirmForMatch: React.FC<
	React.PropsWithChildren<{
		middleware: AppMiddleware;
		bookId: number;
		onConfirm: () => void;
		onChangeBook: () => void;
		onCancel: () => void;
	}>
> = ({ middleware, bookId, onConfirm, onChangeBook, onCancel }) => {
	const [book, setBook] = useState<IBookModel | null>(null);

	useEffect(() => {
		const subs = [middleware.books.byId$(bookId).subscribe(setBook)];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [bookId, middleware.books]);

	return (
		<IonGrid>
			<IonRow className="ion-padding ion-justify-content-center">
				<IonCol sizeXl="6" sizeLg="8" sizeMd="12">
					<h5>
						<b>
							<span id="confirmation-message">Estás a punto de activar este cuaderno:</span>
						</b>
					</h5>
					<div>{book ? book.title : typeof book}</div>
					{book && <BookCoverWraper book={book} middleware={middleware} />}
					<h5>
						Si este es el cuaderno correcto, selecciona Activar. De lo contrario, puedes seleccionar otro
						cuaderno, pero recuerda que este código solo te permite activar un cuaderno. Es posible que por
						un error de fabricación, tu cuaderno haya venido con un código que corresponde a otro cuaderno.
					</h5>
				</IonCol>
				<IonCol size="12">
					<IonButton id="btn-activate-book" className="pd-button-primary" onClick={onConfirm}>
						Activar
					</IonButton>
				</IonCol>
				<IonCol size="12">
					<IonButton id="btn-select-book" className="pd-button-outline" onClick={onChangeBook}>
						Elegir otro cuaderno
					</IonButton>
				</IonCol>
				<IonCol size="12">
					<IonButton id="btn-cancel" className="pd-button-outline" onClick={onCancel}>
						Anular
					</IonButton>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

const ConfirmForMismatch: React.FC<
	React.PropsWithChildren<{
		middleware: AppMiddleware;
		codeBookId: number;
		requestedBookId: number;
		onConfirm: (bookId: number) => void;
		onChangeBook: () => void;
	}>
> = ({ middleware, codeBookId, requestedBookId, onConfirm, onChangeBook }) => {
	const [codeBook, setCodeBook] = useState<IBookModel | null>(null);
	const [requestedBook, setRequestedBook] = useState<IBookModel | null>(null);
	const [bookSelected, setBookSelected] = useState<number | null>(null);

	useEffect(() => {
		const subs = [
			middleware.books.byId$(codeBookId).subscribe(setCodeBook),
			middleware.books.byId$(requestedBookId).subscribe(setRequestedBook),
		];

		return () => {
			subs.forEach((s) => s.unsubscribe());
		};
	}, [codeBookId, requestedBookId, middleware.books]);

	return (
		<IonGrid>
			<IonRow className="ion-padding ion-justify-content-center">
				<IonCol sizeXl="6" sizeLg="8" sizeMd="12">
					<IonIcon className="pd-page-title-icon" icon={warningOutline} />
					<h5>
						<b>
							<span id="warning-message">Estabas tratando de activar este cuaderno:</span>
						</b>
					</h5>
					<div>{requestedBook ? requestedBook.title : typeof requestedBook}</div>

					{requestedBook && <BookCoverWraper book={requestedBook} middleware={middleware} />}

					<h5>
						<b>Sin embargo, el código que ingresaste corresponde a otro cuaderno:</b>
					</h5>
					<div>{codeBook ? codeBook.title : typeof codeBook}</div>
					{codeBook && <BookCoverWraper book={codeBook} middleware={middleware} />}

					<h5>
						Es posible que por un error de fabricación, tu cuaderno haya venido con un código que
						corresponde a otro cuaderno. Selecciona el cuaderno que quieres activar, o busca otro. Recuerda
						que este código solo te permite activar un cuaderno.
					</h5>

					<IonList>
						<IonRadioGroup value={bookSelected} onIonChange={(e) => setBookSelected(e.detail.value)}>
							{requestedBook && (
								<IonItem id={`book-id-request-${requestedBook.id}`}>
									<IonLabel>{requestedBook.title}</IonLabel>
									<IonRadio slot="start" value={requestedBook.id} />
								</IonItem>
							)}

							{codeBook && (
								<IonItem id={`book-id-${codeBook.id}`}>
									<IonLabel>{codeBook.title}</IonLabel>
									<IonRadio slot="start" value={codeBook.id} />
								</IonItem>
							)}
						</IonRadioGroup>
					</IonList>
				</IonCol>
				<IonCol size="12">
					<IonButton
						disabled={bookSelected ? false : true}
						id="btn-activate-book"
						className="pd-button-primary"
						onClick={() => onConfirm(bookSelected!)}
					>
						Activar cuaderno
					</IonButton>
				</IonCol>
				<IonCol>
					<IonButton id="btn-select-book" className="pd-button-outline" onClick={onChangeBook}>
						Elegir otro cuaderno
					</IonButton>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};

const BookCoverWraper = (props: { book: IBookModel; middleware: AppMiddleware }) => {
	return (
		<IonRow className="ion-padding ion-justify-content-center">
			<IonCol sizeXs="8" sizeSm="6" sizeMd="6" sizeLg="6" sizeXl="4">
				<div className="pd-book-cover-shadow">
					<BookCover book={props.book} middleware={props.middleware} />
				</div>
			</IonCol>
		</IonRow>
	);
};

export default withTransaction('ActivateBookPage', 'component')(ActivateBookPage);
