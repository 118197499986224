import React, { useCallback, useEffect, useRef } from 'react';

const GenericPopover: React.FC<
	React.PropsWithChildren<{
		pos: any;
		show: any;
		eventType?: any;
		modeState: any;
	}>
> = (props) => {
	const { pos, show, eventType, modeState } = props;
	const popoverContentRef = useRef<HTMLDivElement>(null);
	const popoverRef = useRef<HTMLDivElement>(null);

	const displayPopover = useCallback(() => {
		popoverContentRef.current!.style.position = 'fixed';
		popoverContentRef.current!.style.bottom = `${pos.current!.clientHeight + 10}px`;
		popoverContentRef.current!.style.left =
			pos.current!.offsetLeft + popoverContentRef.current!.clientWidth > window.innerWidth
				? `${window.innerWidth - popoverContentRef.current!.clientWidth}px`
				: `${pos.current!.offsetLeft}px`;
		popoverRef.current!.style.display = 'flex';
	}, [pos]);

	const handleMouseDown = useCallback(() => {
		if (show.showPopover) {
			displayPopover();
			popoverRef.current!.style.backgroundColor = 'transparent';
			popoverContentRef.current!.style.animation = 'popoverAnimationDiscover 1000ms ease 0ms normal none';
		} else popoverRef.current!.style.display = 'none';
	}, [show, displayPopover]);

	const handlePress = useCallback(() => {
		if (show.showPopover) {
			displayPopover();
			popoverRef.current!.style.backgroundColor = 'rgba(0,0,0,0.4)';
			popoverContentRef.current!.style.animation = 'popoverAnimation 300ms ease 0ms normal none';
		}
	}, [show, displayPopover]);

	const handleTap = useCallback(() => {
		if (show.showPopover) {
			displayPopover();
			popoverRef.current!.style.backgroundColor = 'transparent';
		} else popoverRef.current!.style.display = 'none';
	}, [show, displayPopover]);

	useEffect(() => {
		if (eventType !== undefined && pos.current! !== null) {
			if (!show.showPopover && show.btnMoveCalled) popoverRef.current!.style.display = 'none';
			else {
				switch (eventType.current!) {
					case 'mousedown':
						handleMouseDown();
						break;
					case 'press':
						handlePress();
						break;
					case 'tap':
						handleTap();
						break;
					case '':
						popoverRef.current!.style.display = 'none';
						break;
				}
			}
		} else if (show.showPopover) {
			displayPopover();
		} else popoverRef.current!.style.display = 'none';
	}, [show, pos, eventType, modeState, displayPopover, handleMouseDown, handlePress, handleTap]);

	return (
		<>
			<div ref={popoverRef} onClick={() => (popoverRef.current!.style.display = 'none')} className="popover">
				<div ref={popoverContentRef} className="popover-content">
					{props.children}
				</div>
			</div>
		</>
	);
};

export default GenericPopover;
