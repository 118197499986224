// Tener observable que diga si la sincronizacion esta permitida
// 1. Verifica Usuario
// 2. Verifica Internet
// Si cambia 1. o 2. revizar grant
// Verificar descarte -> dsede que se perdio el se descartan los trazos

// https://pouchdb.com/guides/replication.html

import { combineLatest, interval, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGrantModel } from '../models/IGrantModel';
import { APIRequest } from './APIRequest';
import { AppMiddleware } from './AppMiddleware';
import { PrivateResourceMiddleware } from './PrivateResourceMiddleware';
import { Logger } from '../utils/logging';

const logger = new Logger('GrantsMiddleware');

export class GrantsMiddleware extends PrivateResourceMiddleware<IGrantModel> {
	private readonly readyPromise: Promise<void>;

	constructor(private middleware: AppMiddleware) {
		super('grants', '/book/my-grants', middleware.user.currentUser$);

		this.readyPromise = new Promise(async (resolve) => {
			await this.dispatchLocalData();
			resolve();
		});

		const second = 60; // TODO: env
		interval(second * 1000).subscribe(() => {
			this.updateGrants();
		});
	}

	public isValidForAuth$(bookAuthorizationId: number): Observable<boolean> {
		return combineLatest([this.forAuth$(bookAuthorizationId), this.middleware.device.deviceId$]).pipe(
			map(([grant, deviceId]) => {
				logger.info('isValidForAuth$', grant, deviceId);
				return !!(grant && deviceId && grant.deviceId === deviceId);
			}),
		);
	}

	public forAuth$(bookAuthorizationId: number) {
		return this.all$.pipe(
			map((items) => {
				return items.find((i) => i.bookAuthorizationId === bookAuthorizationId);
			}),
		);
	}

	public async requestGrant(bookAuthorizationId: number, force: boolean = false) {
		try {
			const res = await APIRequest.post(`/book/my-authorizations/${bookAuthorizationId}/grant`, {
				deviceId: this.middleware.device.deviceId,
				force,
			});
			if (!this.middleware.user.getExternalUserType()) this.updateGrants();
			return res.data;
		} catch (err) {
			const response = (err as any).response;
			if (response?.status === 400) {
				const deviceError = response?.data?.errors?.find(
					(error: any) => error.property === 'deviceId' && error?.constraints?.hasOwnProperty('isExists'),
				);
				if (deviceError) {
					await this.middleware.device.requestDeviceId();
					this.updateGrants();
				} else {
					throw err;
				}
			} else {
				this.updateGrants();
				throw err;
			}
		}
	}

	public async checkGrantLost(bookAuthorizationId: number) {
		try {
			await APIRequest.post(`/book/my-authorizations/${bookAuthorizationId}/grant`, {
				deviceId: this.middleware.device.deviceId,
			});
			return null;
		} catch (err) {
			if ((err as any).response?.data?.code === 'errors.logic.withoutAuthorizationGrant') {
				return new Date((err as any).response.data.lostAt);
			} else {
				throw err;
			}
		}
	}

	public async getAllGrants() {
		this.resetThrottle();
		return await this.getRemoteData();
	}

	public updateGrants() {
		this.resetThrottle();
		this.getAllData();
	}

	public ready() {
		return this.readyPromise;
	}
}
