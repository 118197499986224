import { getImageAsArrayBuffer } from '../utils/request';
import { LRUCache } from '../utils/LRUCache';
import { IDBWrapper } from '../utils/IDBWrapper';
import { AbstractDecrypter } from '../utils/crypto';
import { Config } from '../utils/config';
import { BehaviorSubject } from 'rxjs';
import { Logger } from '../utils/logging';
import { resizeImage } from '../utils/resizeImage';

const logger = new Logger('AssetsMiddleware');

const getMimeType = (ba: ArrayBuffer) => {
	const view = new Uint8Array(ba).subarray(0, 4);
	const bytes: string[] = [];
	view.forEach((byte) => bytes.push(byte.toString(16)));
	const magic = bytes.join('').toUpperCase();

	switch (magic) {
		case '89504E47':
			return 'image/png';
		case '47494638':
			return 'image/gif';
		case '25504446':
			return 'application/pdf';
		case 'FFD8FFDB':
		case 'FFD8FFE0':
			return 'image/jpeg';
		case '504B0304':
			return 'application/zip';
		default:
			return `Unknown: ${magic}`;
	}
};

const DECRYPTER = AbstractDecrypter.get(Config.API_IMAGE_KEY);

export class AssetsMiddleware {
	private readonly idb = new IDBWrapper('assets');

	private readonly activeFetches: Map<string, Promise<ArrayBuffer>> = new Map();

	public readonly activeFetchesCount$ = new BehaviorSubject<number>(0);

	// Cache images as blobs (see createObjectURL/revokeObjectURL)
	// Perform decryption if url ends with .dat
	protected readonly imagesCache = new (class extends LRUCache<string> {
		constructor(private mw: AssetsMiddleware) {
			super(50);
		}

		async fetch(key: string): Promise<string | null> {
			let ba = await this.mw.get(key);
			if (!ba) return null;

			if (key.endsWith('.dat')) ba = await DECRYPTER.decrypt(ba);
			logger.info(`imagesCache.fetch [${key}] - mime type: ${getMimeType(ba)}`);

			const blob = new Blob([ba], { type: 'image/jpg' });
			return URL.createObjectURL(blob);
		}

		protected evict(key: string, value: string) {
			super.evict(key, value);
			URL.revokeObjectURL(value);
		}
	})(this);

	protected readonly thumbnailsCache = new (class extends LRUCache<string> {
		constructor(private mw: AssetsMiddleware) {
			super(200);
		}

		async fetch(key: string): Promise<string | null> {
			logger.verbose('thumbnailsCache.fetch', key);
			const thumbnailKey = `thumbnail-${key}`;
			let ba = await this.mw.getLocalData(thumbnailKey);

			if (!ba) {
				logger.verbose('thumbnailsCache.fetch: not found', thumbnailKey);
				ba = await this.mw.get(key);
				if (!ba) return null;

				logger.verbose('thumbnailsCache.fetch: got original image');
				if (key.endsWith('.dat')) ba = await DECRYPTER.decrypt(ba);
				logger.info(`imagesCache.fetch [${key}] - mime type: ${getMimeType(ba)}`);

				ba = await resizeImage(ba, 250);
				logger.verbose('thumbnailsCache.fetch: image resized');

				if (ba) await this.mw.putLocalData(thumbnailKey, ba);
			}

			if (ba) {
				const blob = new Blob([ba], { type: 'image/jpg' });
				return URL.createObjectURL(blob);
			} else {
				return null;
			}
		}

		protected evict(key: string, value: string) {
			super.evict(key, value);
			URL.revokeObjectURL(value);
		}
	})(this);

	public ready(): Promise<void> {
		return Promise.resolve();
	}

	// Returns a blob url representing the image
	// If the original url ends with .dat, the image is also decrypted.
	public async getImage(url: string): Promise<string | null> {
		return this.imagesCache.get(url);
	}

	// Returns a blob url representing a thumbnail of the image
	// If the original url ends with .dat, the image is also decrypted.
	public async getThumbnail(url: string): Promise<string | null> {
		return this.thumbnailsCache.get(url);
	}

	private async fetch(url: string): Promise<ArrayBuffer | null> {
		logger.info('fetch', url);

		let activeFetch = this.activeFetches.get(url);
		if (activeFetch) {
			logger.info('    fetch - miss, fetch already in progress');
			// it is important to "recatch" exceptions here, otherwise we will get
			// Unhandled Rejection errors
			return activeFetch.catch((err) => null);
		}

		logger.info('    fetch - miss, queueing fetch', url);
		activeFetch = getImageAsArrayBuffer(url);
		this.activeFetches.set(url, activeFetch);
		this.activeFetchesCount$.next(this.activeFetches.size);

		// This try/catch block is for network errors only
		let data = null;
		let error: any = null;
		try {
			data = await activeFetch;
		} catch (e: any) {
			error = e;
		}

		if (data) {
			// We do not put this inside the try/catch block above, because a
			// failure here is not a network error but probably a programming error
			logger.info('    fetch - fetched', url);
			await this.putLocalData(url, data);
			logger.info('    fetch - cached', url);
		} else {
			logger.error('    fetch - failed', url, error.message);
		}

		this.activeFetches.delete(url);
		this.activeFetchesCount$.next(this.activeFetches.size);

		return data;
	}

	public async get(url: string): Promise<ArrayBuffer | null> {
		if (!url) throw new Error(`URL cannot be empty: ${url}`);
		logger.info('get', url);

		const cached = await this.getLocalData(url);
		if (cached) {
			logger.info('    get - got local data');
			return cached;
		} else {
			return this.fetch(url);
		}
	}

	// Checks whether an asset is cached
	public isCached(url: string): Promise<boolean> {
		return this.hasLocalData(url);
	}

	// Fetch an asset if it is not already cached
	public async ensureCached(url: string): Promise<void> {
		const cached = await this.hasLocalData(url);
		if (!cached) await this.fetch(url);
	}

	private async putLocalData(url: string, data: ArrayBuffer) {
		await this.idb.put(url, data);
	}

	private async getLocalData(url: string): Promise<ArrayBuffer | null> {
		return await this.idb.get(url);
	}

	private async hasLocalData(url: string): Promise<boolean> {
		// TODO: reimplement using IDB.getKey
		return !!(await this.getLocalData(url));
	}
}
