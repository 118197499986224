import { ErrorMessage } from '@hookform/error-message';
import {
	IonButton,
	IonCol,
	IonDatetime,
	IonGrid,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonPopover,
	IonRow,
} from '@ionic/react';
import dayjs from 'dayjs';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { Control, Controller, DeepMap, FieldError, RegisterOptions } from 'react-hook-form';
import { ErrorMessageForm } from './ErrorMessageForm';
export const FormInput: React.FC<{
	control: Control<any>;
	label: string;
	name: string;
	errors: DeepMap<Record<string, any>, FieldError>;
	rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
	type?:
		| 'number'
		| 'time'
		| 'text'
		| 'tel'
		| 'url'
		| 'email'
		| 'search'
		| 'date'
		| 'password'
		| 'week'
		| 'month'
		| 'datetime-local';
}> = (props) => {
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const dateTimeDetailFormat = (value: any) => {
		if (value) return dayjs(value).format('DD-MM-YYYY');
	};
	return (
		<IonGrid>
			<IonRow className="ion-justify-content-center">
				<IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="6" sizeXl="4">
					<IonItem lines="inset" id={props.label}>
						<IonLabel className="ion-padding-bottom" position="floating">
							{props.label}
						</IonLabel>
						<Controller
							render={({ field: { onChange, value }, formState }) =>
								props.type === 'date' ? (
									<>
										<div className="pd-form-input">
											<IonInput value={dateTimeDetailFormat(value)}></IonInput>
										</div>
										<IonPopover
											trigger={props.label}
											showBackdrop={true}
											keepContentsMounted={true}
											size="cover"
										>
											<IonDatetime
												locale="default"
												preferWheel={true}
												presentation="date"
												doneText="Aceptar"
												cancelText="Cancelar"
												value={value}
												onIonChange={onChange}
												max={new Date(Date.now()).toISOString()}
												showDefaultButtons={true}
												size="cover"
												multiple={false}
											/>
										</IonPopover>
									</>
								) : (
									<div className="pd-form-input">
										<IonInput
											id={props.name}
											type={
												!props.type || props.type === 'number'
													? 'text'
													: props.type === 'password' && showPassword
														? 'text'
														: props.type
											}
											value={value}
											inputmode={props.type === 'number' ? 'numeric' : 'text'}
											onIonChange={onChange}
										/>

										{value && props.type === 'password' && (
											<IonButton
												color="transparent"
												className="pd-icon-password"
												onClick={() => setShowPassword(!showPassword)}
											>
												<IonIcon
													color="dark"
													icon={showPassword ? eyeOffOutline : eyeOutline}
												/>
											</IonButton>
										)}
									</div>
								)
							}
							control={props.control}
							name={props.name}
							rules={props.rules}
						/>
					</IonItem>
					<ErrorMessage
						errors={props.errors}
						name={props.name}
						render={({ message }: any) => <ErrorMessageForm id={`error_${props.name}`} message={message} />}
					/>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
};
