import React, { useEffect } from 'react';
import { AppMiddleware } from './middleware/AppMiddleware';
import { useHistory } from 'react-router';
import { Logger } from './utils/logging';
import { App as CapApp } from '@capacitor/app';

const logger = new Logger('AppUrlListener');

const AppUrlListener: React.FC<
	React.PropsWithChildren<{
		middleware: AppMiddleware;
	}>
> = ({ middleware }) => {
	const history = useHistory();

	useEffect(() => {
		CapApp.addListener('appUrlOpen', async (data: any) => {
			const url = new URL(data.url);
			logger.info('appUrlOpen', data, url);

			if (url.protocol === 'cl.caligrafix.pd:') {
				if (url.pathname === '/my-account/google') {
					const token = url.searchParams.get('token') as string;
					await middleware.user.loginWithToken(token);
					history.replace('/welcome', { direction: 'none' });
				}
			}

			if (url.pathname === '/activate-account') {
				const email = url.searchParams.get('email') as string;
				const code = url.searchParams.get('code') as string;
				logger.info('Activating account', email, code);

				await middleware.user.activateAccount(email, code);
				history.replace('/welcome', { direction: 'none' });
			}
		});

		return () => {
			CapApp.removeAllListeners();
		};
	}, [history, middleware.user]);

	return null;
};

export default AppUrlListener;
