// @ts-ignore
import { withTransaction } from '@elastic/apm-rum-react';
import { IonButton, IonCol, IonContent, IonGrid, IonImg, IonRow, IonText } from '@ionic/react';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import img from '../assets/welcome.jpg';
import { ToastApp } from '../components/ToastApp';
import { IonFullscreenPage } from '../helpers/Fullscreen';
import useNotify from '../hooks/useNotify';
import { AppMiddleware } from '../middleware/AppMiddleware';

const RecoveryPinPage: React.FC<React.PropsWithChildren<{ middleware: AppMiddleware }>> = ({ middleware }) => {
	const history = useHistory();
	const notify = useNotify();
	const authorizationId: number = +useParams<{ authorizationId: string }>().authorizationId;
	const bookId: number = +useParams<{ bookId: string }>().bookId;

	const handleBack = () => {
		history.replace(`/books/leave/${bookId}/${authorizationId}`, { direction: 'none' });
	};

	const handleLogout = async () => {
		try {
			await middleware.user.logout();
			history.replace('/menu/account', { direction: 'none' });
		} catch (error) {
			notify.showErrorNotify(error);
		}
	};

	return (
		<IonFullscreenPage className="ion-text-center">
			<IonContent className="ion-text-center ion-padding" fullscreen>
				{notify.notifyProps.show && (
					<ToastApp notify={notify.notifyProps} onDidDismiss={notify.onDidDismissNotify} />
				)}
				<IonGrid>
					<IonRow className="ion-justify-content-center">
						<IonCol size="12">
							<h5>
								<b>Ayuda con tu PIN</b>
							</h5>
						</IonCol>
						<IonCol sizeXs="10" sizeSm="9" sizeMd="8" sizeLg="4" sizeXl="2" className="pd-tutorial-img">
							<IonImg src={img} alt="welcome" />
						</IonCol>
						<IonCol size="12">
							<IonText className="ion-text-center ion-padding">
								Para ver o cambiar tu PIN tendrás que iniciar sesión nuevamente.
							</IonText>
						</IonCol>
						<IonCol size="10" className="ion-padding">
							<IonButton id="btn-back" className="pd-button-outline" onClick={handleBack}>
								Volver
							</IonButton>
							<IonButton id="btn-recovery-pin" className="pd-button-primary" onClick={handleLogout}>
								Cerrar sesión
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonFullscreenPage>
	);
};

export default withTransaction('RecoveryPinPage', 'component')(RecoveryPinPage);
