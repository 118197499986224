import { from, Observable, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, exhaustMap, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Config } from '../utils/config';
import axios from 'axios';
import { Logger } from '../utils/logging';
import { Network } from '@capacitor/network';

const logger = new Logger('NetworkStatusMiddleware');

export class NetworkStatusMiddleware {
	public readonly apiStatus$: Observable<boolean>;
	public readonly networkStatus$: Observable<boolean>;

	constructor() {
		this.networkStatus$ = new Observable<boolean>((subscriber) => {
			Network.getStatus()
				.then((status) => {
					logger.verbose('Initial network status', status);
					subscriber.next(status.connected);
				})
				.catch((error) => {
					subscriber.error(error);
				});

			const networkListener = Network.addListener('networkStatusChange', (status) => {
				logger.info('Network status', status);
				subscriber.next(status.connected);
			});

			return () => {
				networkListener.remove();
			};
		});

		this.apiStatus$ = this.httpGetOK$(`${Config.API_URL}/../healthcheck`);
	}

	public async ready() {
		return Promise.resolve();
	}

	/**
	 * Creates an observable with a boolean value that indicates
	 * if an URL is reacheable and returns a 200 status
	 */
	private httpGetOK$(url: string): Observable<boolean> {
		return this.networkStatus$.pipe(
			switchMap((_) => timer(0, Config.API_HEALTHCHECK_INTERVAL_MS)),
			exhaustMap((_) =>
				from(axios.get(url)).pipe(
					map((_) => true),
					catchError((err) => of(false)),
				),
			),
			distinctUntilChanged(),
			tap((status) => logger.verbose('url status', url, status)),
			shareReplay(1),
		);
	}
}
