import { IonButton, IonCol, IonContent, IonGrid, IonPage, IonRow, IonText } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';
import { HeaderApp } from '../components/HeaderApp';

const PrivacyPoliciesPage: React.FC<React.PropsWithChildren<any>> = (props) => {
	const history = useHistory();

	const handleRouterLink = (link: string) => {
		history.replace(link, { direction: 'none' });
	};

	return (
		<IonPage>
			<HeaderApp goBackTo={'/signup'} />
			<IonContent className="ion-text-center" fullscreen>
				<IonGrid>
					<IonRow className="ion-justify-content-center">
						<IonCol size="9">
							<IonText className="ion-text-left">
								<h2>Políticas de privacidad</h2>

								<p>
									La presente Política de Privacidad establece los términos en que Caligrafix SpA usa
									y protege la información que es proporcionada por sus usuarios al momento de
									utilizar la aplicación Caligrafix Papel Digital. Caligrafix SpA está comprometida
									con la seguridad de los datos de sus usuarios. Esta Política de Privacidad puede
									cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos
									revisar continuamente esta página para asegurarse que está de acuerdo con dichos
									cambios.
								</p>

								<p>
									<b>Información recogida:</b>
								</p>

								<p>
									La aplicación recoge el nombre, la dirección de correo electrónico y la foto de
									perfil (en caso de ingresar a través de servicios de autenticación de terceros como
									Google) del titular de la cuenta. Además recoge el nombre o apodo, y opcionalmente
									la fecha de nacimiento, de los perfiles de usuarios.
								</p>

								<p>
									La aplicación solamente usa la cámara de los dispositivos para escanear los códigos
									impresos en los cuadernos físicos, con el fin de activar su versión digital. Las
									imágenes capturadas por la cámara no son almacenadas ni en el dispositivo, ni en los
									servidores de Caligrafix SpA.
								</p>

								<p>
									Caligrafix SpA Se reserva el derecho de cambiar los términos de la presente Política
									de Privacidad en cualquier momento.
								</p>
							</IonText>
						</IonCol>
					</IonRow>
					<IonRow className="ion-justify-content-center ion-padding">
						<IonCol>
							<IonButton className="pd-button-primary" onClick={() => handleRouterLink(`/signup`)}>
								Volver
							</IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default PrivacyPoliciesPage;
