import React from 'react';
import styles from './BookGrid.module.scss';

export const BookGrid: React.FC<
	React.PropsWithChildren<{
		children?: React.ReactNode;
	}>
> = ({ children }) => {
	return <div className={styles.BookGrid}>{children}</div>;
};
